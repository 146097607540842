import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../../../../utils/components/Icon';

function KAReference({ data, handeLinkClick }) {
  const referenceArticle = useSelector((state) => state.language.data?.referenceArticle);

  if (data?.length) {
    return (
      <div className="kaReference">
        <h4 className="kaReferenceHeading">{referenceArticle}:</h4>
        <ul>
          {data?.map((link, index) => {
            return (
              <li key={index}>
                <a
                  href={link?.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    handeLinkClick(link?.actionId);
                  }}
                >
                  {link?.title}
                  <Icon name="ri-external-link-line" style={{ fontSize: '15px' }} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <></>;
}

export default KAReference;
