import React from 'react';
import Inline from '../../components/inline/Inline';
import Default from '../../components/default/Default';
import { useSelector } from 'react-redux';

function One() {
  const injectionStyle = useSelector((state) => state.flipcard.data.config.injectionStyle);

  const section = () => {
    switch (injectionStyle) {
      case 'inline':
        return <Inline />;
      default:
        return <Default />;
    }
  };

  return section();
}

export default One;
