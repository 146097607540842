import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCtaViewHandler from '../../../ctas/reusable/controllers/useCtaViewHandler';
import useLinkController from '../../../ctas/link/controllers/useLinkController';
import { useCollectEventMutation } from '../../../../services/api';

function Links({ data, keyName }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);

  const { handleCtaView } = useCtaViewHandler();
  const { handleClickLink } = useLinkController();
  const readMore_TEXT = useSelector((state) => state.language.data.readMore_TEXT);
  const [currentLinks, setCurrentLinks] = useState([]);
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);

  useEffect(() => {
    let localData = data?.[keyName]?.map((actionId) => {
      let linkAction = actions.find((action) => action._id === actionId);
      return linkAction;
    });

    if (localData?.length > 0) {
      setCurrentLinks(localData);
    }
  }, [data]);

  const [_CAROUSEL] = useCollectEventMutation();

  // Send carousel view event on component mount
  useEffect(() => {
    const handleViewCarousel = async () => {
      await _CAROUSEL({
        eventData: {
          eventType: 'carouselView',
        },
      });
    };

    handleViewCarousel();
  }, [_CAROUSEL]);

  return (
    <div className="xrayLinks">
      {currentLinks?.map((item, index) => {
        const buttonText = item?.actionData?.buttonText;
        const buttonLink = item?.actionData?.buttonLink;
        const background = item?.background;
        const type = background?.type;
        const color = background?.color;
        const imageURL = background?.imageURL;
        const isColor = type === 'color';
        const isImage = type === 'image';

        return (
          <div
            key={index}
            className="xrayLinkCard"
            onClick={() => {
              handleCtaView(item?._id);
              handleClickLink({
                actionId: item?._id,
                takeActionData: {
                  actionType: 'link',
                },
              });
              window.open(buttonLink, '_blank');
            }}
            style={{
              justifyContent: language === 'he' && 'flex-end',
            }}
          >
            {language === 'he' ? (
              <>
                <div className="xrayCardContent" style={{ alignItems: 'flex-end' }}>
                  <h4 className="thirdLine" style={{ textAlign: 'right' }}>
                    {item?.title}
                  </h4>
                  <p className="primaryTextColor" style={{ fontWeight: 500 }}>
                    {buttonText || readMore_TEXT}
                  </p>
                </div>
                <div>
                  {isImage && <img src={imageURL} />}
                  {isColor && (
                    <div className="xrayLinkCardBgColor" style={{ backgroundColor: color }}></div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div>
                  {isImage && <img src={imageURL} />}
                  {isColor && (
                    <div className="xrayLinkCardBgColor" style={{ backgroundColor: color }}></div>
                  )}
                </div>
                <div className="xrayCardContent">
                  <h4 className="thirdLine">{item?.title}</h4>
                  <p className="primaryTextColor" style={{ fontWeight: 500 }}>
                    {buttonText || readMore_TEXT}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Links;
