import React, { useEffect } from 'react';
import '../style/Two.css';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/DocumentIcon.svg';
import { useCollectEventMutation } from '../../../../services/api';
import ContentTwo from '../components/ContentTwo';
import FooterOne from '../components/FooterOne';
import { useSelector } from 'react-redux';

function CarouselThree() {
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);

  const [_CAROUSEL] = useCollectEventMutation();
  useEffect(() => {
    const handleViewCarousel = async () => {
      await _CAROUSEL({
        eventData: {
          eventType: 'carouselView',
        },
      });
    };

    handleViewCarousel();
  }, [_CAROUSEL]);

  return (
    <div className="carouselTwo bridgedCard">
      <div className="carouselTwoHeading">
        <DocumentIcon style={{ width: '25px', height: '25px', color: primaryColor }} />
        <h2>Reports</h2>
      </div>
      <ContentTwo />
      <FooterOne />
    </div>
  );
}

export default CarouselThree;
