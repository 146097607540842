import React, { useState } from 'react';
import KALogo from '../KALogo';
import { useDispatch, useSelector } from 'react-redux';
import { setIsRagCollapsed } from '../../../../../redux/slices/appSlice';
import usePostMessage from '../../../../../utils/controllers/usePostMessage';
import { getColorOpacityPercentage } from '../../../../../utils/controllers/colors';
import CollapseIcon from '../CollapseIcon';
import InliFAQInputForm from './InliFAQInputForm';
import Icon from '../../../../../utils/components/Icon';

function InlineFAQTopBar({
  handleGenerateAnswer,
  responseData,
  inputQuery,
  setInputQuery,
  isLoading,
}) {
  const exploreAnswers_TEXT = useSelector((state) => state?.language?.data?.exploreAnswers_TEXT);

  const primaryColor = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.primaryColor);
  const injectionStyle = useSelector((state) => state.flipcard.data.config.injectionStyle);
  const [isAlreadyCollapsed, setIsAlreadyCollapsed] = useState(false);
  const { postMessage } = usePostMessage();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };
  const dispatch = useDispatch();
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);

  const handleQuestionClick = () => {
    setIsAlreadyCollapsed(true);
    if (isRagCollapsed) {
      dispatch(setIsRagCollapsed(!isRagCollapsed));
      postMessage({
        event: 'toggleCollapsion',
        trackerId: getQueryParam('trackerId'),
      });
      if (!isAlreadyCollapsed) {
        handleGenerateAnswer(kaFAQQuestions[0] || {}, {
          isAlreadyExpanded: true,
        });
      }
    }
  };

  const handleClick = () => {
    dispatch(setIsRagCollapsed(!isRagCollapsed));
    postMessage({
      event: 'toggleCollapsion',
      trackerId: getQueryParam('trackerId'),
    });
  };

  const isInline = injectionStyle === 'inline';

  const getIconName = () => {
    if (isInline) {
      return isRagCollapsed ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line';
    }
    return isRagCollapsed ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line';
  };

  return (
    <div
      className="kaTopBarOne"
      style={{
        cursor: isRagCollapsed && kaFAQQuestions?.length > 0 && 'pointer',
      }}
      onClick={() => {
        if (kaFAQQuestions?.length > 0) {
          handleQuestionClick();
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 10,
        }}
      >
        <KALogo />
        {isRagCollapsed ? (
          <>
            {kaFAQQuestions?.length > 0 ? (
              <>
                {responseData?.length > 0 ? (
                  <div className="kaCollapseIcon">
                    <Icon name={getIconName()} />
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 400,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        opacity: 0.3,
                      }}
                      className="kaInlineTopBarTopText"
                    >
                      {exploreAnswers_TEXT}
                      <img
                        style={{ height: '15px' }}
                        src="https://prod-assets-156041422845.s3.eu-west-1.amazonaws.com/images/3abbd4ba-d469-4405-a75f-48039a8a1415.Image"
                      />
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                {responseData?.length > 0 && (
                  <div className="kaCollapseIcon">
                    <Icon name={getIconName()} onClick={handleClick} />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <CollapseIcon responseData={responseData} />
        )}
      </div>

      {isRagCollapsed && (
        <>
          {kaFAQQuestions?.length > 0 ? (
            <div
              style={{
                backgroundColor: getColorOpacityPercentage(primaryColor, 0.07),
                padding: 'var(--mpr-3)',
                borderRadius: '0px var(--mpr-mini) var(--mpr-mini) 0px',
                borderLeft: '3px solid var(--primary-Color)',
                color: primaryColor,
              }}
            >
              <h3 style={{ fontSize: '0.9rem' }}>{kaFAQQuestions[0]?.title || ''}</h3>
            </div>
          ) : (
            <InliFAQInputForm
              handleGenerateAnswer={handleGenerateAnswer}
              inputQuery={inputQuery}
              setInputQuery={setInputQuery}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </div>
  );
}

export default InlineFAQTopBar;
