import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useErrorHandler from '../controllers/useErrorHandler';

function ErrorWrapper({ children }) {
  // Redux selector to get flipcard state
  const flipcard = useSelector((state) => state.flipcard);

  // Custom hook to handle errors
  const { postError } = useErrorHandler();

  // Function to handle errors and post them
  const handleError = (message) => {
    postError(message);
    // Return true to prevent the default browser error handling
    return true;
  };

  // Effect to set up global error handling
  useEffect(() => {
    // Attach error handler to window.onerror
    window.onerror = handleError;

    // Clean up function to remove error handler
    return () => {
      window.onerror = null;
    };
  }, [flipcard]); // Dependency added to ensure effect runs when flipcard state changes

  return children;
}

export default ErrorWrapper;
