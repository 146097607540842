import React from 'react';
import '../styles/sliderPoll.css';
import TopBar from '../../reusable/components/TopBar';
import Body from '../components/Body';
import EngagementFooter from '../../reusable/components/footers/EngagementFooter';

function SliderPollWorkflow() {
  return (
    <div className="sliderPoll">
      <TopBar />
      <Body />
      <EngagementFooter />
    </div>
  );
}

export default SliderPollWorkflow;
