import React from 'react';
import GatedForm from './GatedForm';
import InputForm from './InputForm';
import GatedBanner from './GatedBanner';
import KALoader from '../KALoader';
import { useSelector } from 'react-redux';

function SinglePageInput({
  isShowEmailForm,
  bgColorOpacity,
  isShowBanner,
  isLoading,
  inputQuery,
  setInputQuery,
  responseData,
  handleGenerateAnswer,
}) {
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);
  return (
    <>
      {isShowEmailForm ? (
        <GatedForm bgColorOpacity={bgColorOpacity} />
      ) : (
        <InputForm
          bgColorOpacity={bgColorOpacity}
          isShowBanner={isShowBanner}
          isLoading={isLoading}
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          responseData={responseData}
          handleGenerateAnswer={handleGenerateAnswer}
        />
      )}

      {isShowBanner && <GatedBanner />}

      {isLoading && (
        <div
          style={{
            marginTop: 'var(--mpr-2)',
            marginBottom: 'var(--mpr-mini)',
            textAlign: 'center',
          }}
        >
          <KALoader isLoading={isLoading} responseData={responseData} loaderColor={primaryColor} />
        </div>
      )}
    </>
  );
}

export default SinglePageInput;
