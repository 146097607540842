import { useDispatch, useSelector } from 'react-redux';
import { useCtaViewMutation } from '../../../../services/api';
import { addCtaViews } from '../../../../redux/slices/appSlice';
import { useEffect } from 'react';

function useCtaViewHandler() {
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);
  const ctaViews = useSelector((state) => state.app.ctaViews);
  const dispatch = useDispatch();
  const [_VIEW_CTA] = useCtaViewMutation();

  const handleCtaView = async (_id) => {
    if (mode === 'create' || mode === 'preview') {
      return;
    }
    if (_id && !ctaViews.includes(_id)) {
      await _VIEW_CTA({
        actionId: _id,
      });

      dispatch(addCtaViews(_id));
    }
  };

  return { handleCtaView };
}

export default useCtaViewHandler;
