import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBridgedLogo,
  setinlineXrayHeaderSize,
  setIsAnimatedBorderVisible,
  setOuterBorderRadius,
} from '../../redux/slices/appSlice';
import {
  ES_Language,
  LV_Language,
  NL_Language,
  UA_Language,
  US_Language,
  CAT_Language,
  EST_Language,
  CZ_Language,
  DA_Language,
  DE_Language,
  HE_Language,
  RU_Language,
  EL_Language,
  EN_UK_Language,
  BG_Language,
} from '../../constants/flipcard/languageState';
import { setLanguage } from '../../redux/slices/languageSlice';
import { getColorOpacityPercentage } from './colors';

function useThemeHandler() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.flipcard?.data?.flipCard?.theme);

  const isDarkTheme = theme?.darkTheme || false;
  const primaryColor = theme?.primaryColor;
  const language = theme?.language || 'en';
  const fontFamilyType = theme?.fontFamily?.fontType || 'googleFont';
  const fontFamily = theme?.fontFamily?.fontName || 'Quicksand';
  const fontURL = theme?.fontFamily?.fontURL || '';
  const fileExtension = fontURL.split('.').pop();
  const fontScale = theme?.fontScale || 1.0;
  const logoScale = theme?.logoScale || 1.0;
  const outerBorderRadius = theme?.outerBorderRadius || '15px';
  const isAnimatedBorderVisible = theme?.animatedBorder?.isActive || false;
  const shineBorderColor1 = theme?.animatedBorder?.color1 || '#7c3aed';
  const shineBorderColor2 = theme?.animatedBorder?.color2 || '#fe8fb5';
  const shineBorderColor3 = theme?.animatedBorder?.color3 || '#ffbe7b';

  useEffect(() => {
    const styleElement = document.createElement('style');

    if (fontFamilyType === 'googleFont') {
      styleElement.innerHTML = `
        @import url('https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700&display=swap');
        * {
          font-family: '${fontFamily}', sans-serif;
        }
      `;
      document.head.appendChild(styleElement);
    } else if (fontFamilyType === 'customFont' && fontURL) {
      styleElement.innerHTML = `
        @font-face {
          font-family: 'User Uploaded Font';
          font-style: normal;
          font-weight: normal;
          font-display: swap;
          src: url(${fontURL}) format('${fileExtension}');
        }
        * {
          font-family: "User Uploaded Font", sans-serif;
        }
      `;
      document.head.appendChild(styleElement);
    }

    return () => {
      if (document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, [theme]);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      html {
        font-size: ${fontScale * 100}%; !important;
      }
      `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [fontScale]);

  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.style.setProperty('--text-Color', 'white');
      document.documentElement.style.setProperty('--background-Color', '#171717');
      document.documentElement.style.setProperty('--fill-Color', '#202020');
      document.documentElement.style.setProperty('--fill-Color-Hover', '#0c0c0c');
    } else {
      document.documentElement.style.setProperty('--text-Color', '#000');
      document.documentElement.style.setProperty('--background-Color', 'white');
      document.documentElement.style.setProperty('--fill-Color', '#ebebeb');
      document.documentElement.style.setProperty('--fill-Color-Hover', '#dfdfdf');
    }

    dispatch(setBridgedLogo(isDarkTheme ? '/bridged_logo_white.png' : '/bridged_logo_black.png'));
  }, [isDarkTheme]);

  useEffect(() => {
    if (primaryColor) {
      document.documentElement.style.setProperty('--primary-Color', primaryColor);
      document.documentElement.style.setProperty(
        '--primary-Color-Opacity',
        getColorOpacityPercentage(primaryColor, 0.1)
      );
    }
  }, [primaryColor]);

  useEffect(() => {
    if (language) {
      let currentLanguage = US_Language;
      switch (language) {
        case 'en':
          currentLanguage = US_Language;
          break;
        case 'es':
          currentLanguage = ES_Language;
          break;
        case 'nl':
          currentLanguage = NL_Language;
          break;
        case 'lv':
          currentLanguage = LV_Language;
          break;
        case 'ua':
          currentLanguage = UA_Language;
          break;
        case 'da':
          currentLanguage = DA_Language;
          break;
        case 'cat':
          currentLanguage = CAT_Language;
          break;
        case 'est':
          currentLanguage = EST_Language;
          break;
        case 'cz':
          currentLanguage = CZ_Language;
          break;
        case 'de':
          currentLanguage = DE_Language;
          break;
        case 'ru':
          currentLanguage = RU_Language;
          break;
        case 'en-uk':
          currentLanguage = EN_UK_Language;
          break;
        case 'he':
          currentLanguage = HE_Language;
          break;
        case 'el':
          currentLanguage = EL_Language;
          break;
        case 'bg':
          currentLanguage = BG_Language;
          break;
        default:
          currentLanguage = US_Language;
      }

      dispatch(setLanguage(currentLanguage));
    }
  }, [language]);

  useEffect(() => {
    let newLogoScaleSmall = logoScale * 20;
    let newLogoScaleLarge = logoScale * 100;

    let inlineXrayHeaderSize = 55 + logoScale * 20;

    document.documentElement.style.setProperty('--logo-height-small', `${newLogoScaleSmall}px`);
    document.documentElement.style.setProperty('--logo-height-large', `${newLogoScaleLarge}px`);

    dispatch(setinlineXrayHeaderSize(inlineXrayHeaderSize));
  }, [logoScale]);

  useEffect(() => {
    dispatch(setOuterBorderRadius(outerBorderRadius));
    document.documentElement.style.setProperty('--outer-border-radius', outerBorderRadius);
  }, [outerBorderRadius]);

  useEffect(() => {
    dispatch(setIsAnimatedBorderVisible(isAnimatedBorderVisible));
  }, [isAnimatedBorderVisible]);

  useEffect(() => {
    document.documentElement.style.setProperty('--shine-border-color1', shineBorderColor1);
    document.documentElement.style.setProperty('--shine-border-color2', shineBorderColor2);
    document.documentElement.style.setProperty('--shine-border-color3', shineBorderColor3);
  }, [shineBorderColor1, shineBorderColor2, shineBorderColor3]);
}

export default useThemeHandler;
