import { useDispatch, useSelector } from 'react-redux';
import { useCtaTakeMutation } from '../../../../services/api';
import { setIsCtaTaken, setIsShowEndScreen } from '../../../../redux/slices/appSlice';
import useMessage from '../../../../utils/presentation/message/controllers/useMessage';
import useTriggerEngagementCtaTake from '../../../../utils/controllers/useTriggerEngagementCtaTake';

function useLinkController() {
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const isEndScreen = useSelector((state) => state.flipcard?.data?.flipCard?.endScreen?._id);
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const { showMessage } = useMessage();
  const dispatch = useDispatch();
  const [_CTA_TAKE, { isLoading }] = useCtaTakeMutation();
  const { triggerActionTake } = useTriggerEngagementCtaTake();

  const handleEndScreen = () => {
    if (isEndScreen) {
      dispatch(setIsShowEndScreen(true));
    }
  };

  const handleClickLink = async (sentData) => {
    if (mode === 'create') {
      return;
    }
    const response = await _CTA_TAKE(sentData);

    if (response?.data?.success) {
      triggerActionTake();
      dispatch(setIsCtaTaken(true));
      if (ctas?.length === 0) {
        handleEndScreen();
      }
    }

    if (response?.error) {
      showMessage('error', response?.error?.data?.errorObject?.userErrorText);
    }
  };

  return { handleClickLink, isLoading };
}

export default useLinkController;
