import React from 'react';
import { useSelector } from 'react-redux';
import '../styles/engagementFooter.css';

function EngagementFooter() {
  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  if (footer?.isActive) {
    return (
      <div className="engagementFooter">
        <h5
          dangerouslySetInnerHTML={{
            __html: footer?.title,
          }}
        />
      </div>
    );
  }

  return <></>;
}

export default EngagementFooter;
