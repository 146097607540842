import { useDispatch, useSelector } from 'react-redux';
import useEngController from '../../reusable/controllers/useEngController';
import { useEffect } from 'react';
import { setIsShowEngResult } from '../../../../redux/slices/appSlice';

const optionsAlphabet = ['A', 'B', 'C', 'D'];

function Body() {
  const dispatch = useDispatch();
  const { handleEngTake, selectedId } = useEngController();

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isShowEngResult = useSelector((state) => state.app.isShowEngResult);
  const isGatedEngagement = useSelector((state) => state?.flipcard?.data?.config?.gatedEngagement);

  const engagement = useSelector((state) => state.flipcard.data.flipCard.engagement);

  const options = engagement?.engagementData?.options;

  const handleOptionClicked = (optionId) => {
    dispatch(setIsShowEngResult(!isGatedEngagement));
    handleEngTake(optionId);
  };

  useEffect(() => {
    dispatch(setIsShowEngResult(isCtaTaken));
  }, [isCtaTaken]);

  return (
    <div className="engInputMainDiv">
      {options?.map((data, index) => {
        const isSelected = data._id === selectedId;

        const progressBarStyle = {
          width: isShowEngResult && isSelected ? '100%' : '0%',
          visibility: isShowEngResult && selectedId ? 'visible' : 'hidden',
          backgroundColor: 'var(--primary-Color)',
        };

        return (
          <div
            key={index}
            onClick={() => handleOptionClicked(data._id)}
            className={`engInputBar ${isShowEngResult && isSelected && 'gatewaySelected'}`}
          >
            <div className="engBgProgressBar" style={progressBarStyle} />

            <h5>{optionsAlphabet[index]}</h5>
            <h5 className="engInputText">{data.title}</h5>
          </div>
        );
      })}
    </div>
  );
}

export default Body;
