import React from 'react';
import useKnowledgeAgentController from '../controllers/useKnowledgeAgentController';
import SidebarThreeTopBar from '../components/One/SidebarThreeTopBar';
import SidebarThreeBody from '../components/One/SidebarThreeBody';

function SidebarThree() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    error,
    handeLinkClick,
    handRatingTake,
  } = useKnowledgeAgentController();

  return (
    <div className="kaSidebarThree">
      <SidebarThreeTopBar
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        error={error}
      />
      <SidebarThreeBody
        responseData={responseData}
        handeLinkClick={handeLinkClick}
        handRatingTake={handRatingTake}
        error={error}
        isLoading={isLoading}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
      />
    </div>
  );
}

export default SidebarThree;
