import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CtaWorkflow from '../../ctas/workflow/CtaWorkflow';

function GatedEngagementWorkflow() {
  const [gatedCtaIndex, setGatedCtaIndex] = useState(undefined);
  // Select necessary data from Redux store
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isGatedEngagement = useSelector((state) => state?.flipcard?.data?.config?.gatedEngagement);
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const isEngTaken = useSelector((state) => state.app.isEngTaken);
  const isCtas = ctas?.length > 0;

  // Function to determine if gated engagement overlay should be shown
  const shouldShowGatedEngagement = () => {
    return isGatedEngagement && isEngTaken && !isCtaTaken && isCtas;
  };

  useEffect(() => {
    const index = ctas.findIndex((data, index) => {
      const actionType = data?.actionData?.actionType;
      if (
        actionType === 'newsLetterSignup' ||
        actionType === 'emailCollection' ||
        actionType === 'registration'
      ) {
        return data;
      }
    });

    if (index != -1) {
      setGatedCtaIndex(index);
    }
  }, [ctas]);

  return (
    <>
      {shouldShowGatedEngagement() && (
        <div className="gatedCtaOverlay">
          <CtaWorkflow actionIndex={gatedCtaIndex || 0} isTransparent={true} />
        </div>
      )}
    </>
  );
}

export default GatedEngagementWorkflow;
