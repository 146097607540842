import React from 'react';
import { useSelector } from 'react-redux';

function OneFAQ({ handleGenerateAnswer, responseDataLength }) {
  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);

  const suggestedQuestions = useSelector((state) => state.language.data.suggestedQuestions);

  if (kaFAQQuestions?.length && responseDataLength === 0) {
    return (
      <div className="kaFaqOneParent">
        <div className="kaFaqOne">
          <h4>{suggestedQuestions}</h4>

          <ul>
            {kaFAQQuestions?.map((data, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data?.title}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  return <></>;
}

export default OneFAQ;
