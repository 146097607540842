import React, { useEffect } from 'react';
import useGoogleAdManagerScript from '../controllers/useGoogleAdManagerScript';

const AdManager = () => {
  useGoogleAdManagerScript();
  useEffect(() => {
    // Ensure googletag is defined
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display('div-gpt-ad-1721792486313-0');
      });
    }
  }, []);

  return (
    <div id="div-gpt-ad-1721792486313-0" style={{ minWidth: '300px', minHeight: '250px' }}>
      {/* This script will be executed by googletag.display */}
    </div>
  );
};

export default AdManager;
