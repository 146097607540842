import React from 'react';
import '../styles/gateway.css';
import TopBar from '../../reusable/components/TopBar';
import Body from '../components/Body';
import EngagementFooter from '../../reusable/components/footers/EngagementFooter';

function GatewayWorkflow() {
  return (
    <>
      <div className="gateway">
        <TopBar isHideAnalytics={true} />
        <Body />
        <EngagementFooter />
      </div>
    </>
  );
}

export default GatewayWorkflow;
