import React from 'react';
import FAQ from './FAQ';
import DmgInputBox from './DmgInputBox';
import DmgLogoTitle from './DmgLogoTitle';

function MiddleBar({ inputQuery, setInputQuery, isLoading, onHandleDmgClick }) {
  return (
    <div className="dmgMiddleBar">
      <div className="dmgMiddleBarData">
        <DmgLogoTitle isLoading={isLoading} />
        <DmgInputBox
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          isLoading={isLoading}
          onHandleDmgClick={onHandleDmgClick}
        />
        <FAQ onHandleDmgClick={onHandleDmgClick} />
      </div>
    </div>
  );
}

export default MiddleBar;
