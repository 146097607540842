import React, { useEffect, useState } from 'react';
import CtaWorkflow from '../../ctas/workflow/CtaWorkflow';
import { useDispatch, useSelector } from 'react-redux';
import EndScreenWorkflow from '../../endScreens/workflow/EndScreenWorkflow';
import CtaCarouselWorkflow from '../../../utils/presentation/carousel/workflow/CtaCarouselWorkflow';
import { setIsShowEndScreen } from '../../../redux/slices/appSlice';

function Back() {
  const dispatch = useDispatch();
  const [isAlreadyFlipped, setIsAlreadyFlipped] = useState(false);

  // Select necessary data from Redux store
  const ctas = useSelector((state) => state?.flipcard?.data?.flipCard?.actions);
  const endScreenData = useSelector((state) => state?.flipcard?.data?.flipCard?.endScreen?._id);
  const flipcard = useSelector((state) => state?.flipcard?.data?.flipCard);
  const isEndScreen = useSelector((state) => state.app.isShowEndScreen);
  const isFlipped = useSelector((state) => state.app.isFlipped);

  // Effect to show end screen if no engagement or actions are present
  useEffect(() => {
    if (!flipcard?.engagement && (!flipcard?.actions || flipcard?.actions?.length === 0)) {
      dispatch(setIsShowEndScreen(true));
    }
  }, [dispatch, flipcard]);

  // Effect to set isAlreadyFlipped state when flip action occurs
  useEffect(() => {
    if (isFlipped) {
      setIsAlreadyFlipped(true);
    }
  }, [isFlipped]);

  // Render appropriate components based on conditions
  if (isEndScreen) {
    return <EndScreenWorkflow />;
  }

  if (isFlipped || isAlreadyFlipped) {
    if (ctas?.length > 1) {
      return <CtaCarouselWorkflow />;
    }
    if (ctas?.length === 1) {
      return <CtaWorkflow actionIndex={0} />;
    }
  }

  if (endScreenData) {
    return <EndScreenWorkflow />;
  }

  // Default return if no conditions are met
  return <div />;
}

export default Back;
