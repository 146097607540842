import React from 'react';
import { useSelector } from 'react-redux';
import Two from './Two';
import One from './One';
import '../styles/knowledgeAgent.css';
import SidebarOne from './SidebarOne';
import SidebarThree from './SidebarThree';
import KAGatedAgent from '../components/KAGatedAgent';
import InlineFAQ from './InlineFAQ';
import SinglePageOne from './SinglePageOne';

function KnowledgeAgentWorkflow() {
  const kaGatedTrigger = useSelector((state) => state.app.kaGatedTrigger);
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const engagementTemplate = useSelector(
    (state) => state.flipcard.data?.flipCard?.engagement?.engagementData?.engagementTemplate
  );

  const injectionStyle = useSelector((state) => state.flipcard.data.config.injectionStyle);

  //! names for the engagement templates
  // - name which best suits for the theme
  // - One - default
  // - Two - topInputBar, inlineQNA
  // - singlePageOne - fullScreen
  // - three -
  // - inlineFAQ -

  const renderComponent = () => {
    if (engagementTemplate === 'two') {
      return <Two />;
    } else if (engagementTemplate === 'singlePageOne') {
      return <SinglePageOne />;
    } else if (engagementTemplate === 'three') {
      return <SidebarThree />;
    } else if (engagementTemplate === 'inlineFAQ') {
      return <InlineFAQ />;
    } else if (injectionStyle === 'sidePanel') {
      return <SidebarOne />;
    }
    return <One />;
  };

  const blurFilter = () => {
    if (injectionStyle === 'sidePanel' || engagementTemplate === 'singlePageOne') {
      return 0;
    }

    if (isCtaTaken) {
      return 0;
    }

    if (kaGatedTrigger) {
      return 5;
    }

    return 0;
  };

  return (
    <>
      {engagementTemplate !== 'singlePageOne' && injectionStyle !== 'sidePanel' ? (
        <KAGatedAgent />
      ) : null}
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          filter: `blur(${blurFilter()}px)`,
          borderRadius: 'inherit',
        }}
      >
        {renderComponent()}
      </div>
    </>
  );
}

export default KnowledgeAgentWorkflow;
