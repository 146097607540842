import React from 'react';
import { useSelector } from 'react-redux';
import getTextColor from '../../../../../utils/controllers/getTextColor';
import useGetWindowWidth from '../../../../../utils/controllers/useGetWindowWidth';

function FourInlineTerminologies({ data }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);
  const width = useGetWindowWidth();

  const textColor = getTextColor(primaryColor) || 'white';

  return (
    <div
      className="terminologiesListContainerFour customScrollBar"
      style={{
        maxHeight: data?.terminology?.length > 5 ? '400px' : 'auto',
        overflowY: data?.terminology?.length > 5 ? 'auto' : 'visible',
      }}
    >
      {data?.terminology?.map((item, index) =>
        width > 500 ? (
          <div key={index} className="terminologyItemFour">
            <div
              className="termFour"
              style={{ color: textColor, textAlign: language === 'he' ? 'right' : 'left' }}
            >
              {item.term}:
            </div>
            <div
              className="definitionFour"
              style={{ textAlign: language === 'he' ? 'right' : 'left' }}
            >
              {item.definition}
            </div>
          </div>
        ) : (
          <div key={index} className="terminologyItemFour">
            <div
              className="definitionFourMobile"
              style={{ textAlign: language === 'he' ? 'right' : 'left' }}
            >
              <span
                className="termFourMobile"
                style={{ color: textColor, textAlign: language === 'he' ? 'right' : 'left' }}
              >
                {item.term}:{' '}
              </span>
              {item.definition}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default FourInlineTerminologies;
