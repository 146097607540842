import React from 'react';
import KAFooter from '../KAFooter';
import { useSelector } from 'react-redux';
import KABody from '../KABody/KABody';

function SidebarOneBody({ responseData, handeLinkClick, handRatingTake }) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  if (!isRagCollapsed) {
    return (
      <>
        <KABody
          responseData={responseData}
          handeLinkClick={handeLinkClick}
          handRatingTake={handRatingTake}
          isAlwaysScrollable={true}
        />
        <KAFooter />
      </>
    );
  }

  return <></>;
}

export default SidebarOneBody;
