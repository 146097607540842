import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Content from './Content';
import Body from './Body';

function Default({ data }) {
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const handleToggleDisplay = (data) => {
    setToggleDisplay(data);
  };

  return (
    data && (
      <div className="newsletter">
        <Content handleToggleDisplay={handleToggleDisplay} data={data} logo={logo} />
        <Body toggleDisplay={toggleDisplay} handleToggleDisplay={handleToggleDisplay} data={data} />
      </div>
    )
  );
}

export default Default;
