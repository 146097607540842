import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Refinery89Ads() {
  const url = useSelector((state) => state.flipcard.data?.config?.refineryAdConfig?.url);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const externalScript = document.createElement('script');
      externalScript.src = 'https://tags.refinery89.com/bridgedmedia.js';
      externalScript.async = true;
      document.head.appendChild(externalScript);

      // Cleanup function to remove the script
      return () => {
        document.head.removeChild(externalScript);
      };
    }
  }, [url]);

  return (
    <div
      style={{
        minHeight: '250px',
      }}
      className="r89-desktop-hpa-atf-notes"
    ></div>
  );
}

export default Refinery89Ads;
