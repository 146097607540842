import React from 'react';
import { useSelector } from 'react-redux';
import useLinkController from '../../../../presentation/ctas/link/controllers/useLinkController';
import useCtaViewHandler from '../../../../presentation/ctas/reusable/controllers/useCtaViewHandler';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date
    .toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    .toUpperCase();
};

function ContentTwo() {
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const { handleClickLink } = useLinkController();
  const { handleCtaView } = useCtaViewHandler();

  return (
    <ul className="carouselTwoList">
      {actions.map((paper, index) => {
        return (
          <li key={index}>
            <a
              href={paper?.actionData?.buttonLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                handleCtaView(paper?._id);
                handleClickLink({
                  actionId: paper?._id,
                  takeActionData: {
                    actionType: 'link',
                  },
                });
              }}
            >
              {paper?.title}
            </a>
            <span>{formatDate(paper?.createdDate)}</span>
          </li>
        );
      })}
    </ul>
  );
}

export default ContentTwo;
