import DmgWorkflow from '../../presentation/engagements/dmg/workflows/DmgWorkflow';
import GatewayWorkflow from '../../presentation/engagements/gateway/workflow/GatewayWorkflow';
import KnowledgeAgentWorkflow from '../../presentation/engagements/knowledgeAgent/workflows/KnowledgeAgentWorkflow';
import PollWorkflow from '../../presentation/engagements/poll/workflow/PollWorkflow';
import QuizWorkflow from '../../presentation/engagements/quiz/workflow/QuizWorkflow';
import SliderPollWorkflow from '../../presentation/engagements/sliderPoll/workflow/SliderPollWorkflow';
import XRayWorkflow from '../../presentation/engagements/x-ray/workflow/XRayWorkflow';

const totalEngagementTypes = [
  {
    name: 'Slider Poll',
    value: 'sliderPoll',
    component: SliderPollWorkflow,
  },
  {
    name: 'Poll',
    value: 'poll',
    component: PollWorkflow,
  },
  {
    name: 'Gateway',
    value: 'gateway',
    component: GatewayWorkflow,
  },
  {
    name: 'Quiz',
    value: 'quiz',
    component: QuizWorkflow,
  },
  {
    name: 'Summary Engagement',
    value: 'x-ray',
    component: XRayWorkflow,
  },
  {
    name: 'Knowledge Agent',
    value: 'rag',
    component: KnowledgeAgentWorkflow,
  },
  {
    name: 'RAG',
    value: 'analyticsRag',
    component: DmgWorkflow,
  },
];

export default totalEngagementTypes;
