import React from 'react';

function KaDeaTable({ ga4Data, columns, tableData }) {
  if (!ga4Data) {
    return <></>;
  }

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }} className="KaDeaTable">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key}>{column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row) => (
          <tr key={row.key}>
            {columns.map((column) => (
              <td key={column.key}>
                {column.dataIndex === 'url' ? (
                  <a
                    href={`https://${row[column.dataIndex]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {row[column.dataIndex]}
                  </a>
                ) : (
                  row[column.dataIndex]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default KaDeaTable;
