import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useNewsletterController from '../../controllers/useNewsletterController';
import Button from '../../../../../utils/components/Button';
import Icon from '../../../../../utils/components/Icon';
import GoogleLogin from '../../../../../utils/presentation/googleLogin/workflow/GoogleLogin';
import CtaFlipIcon from '../../../../../utils/components/CtaFlipIcon';

function LeftAligned({ data: userData }) {
  const campaignType = useSelector((state) => state?.flipcard?.data?.config?.campaignType);
  const language = useSelector((state) => state.language.data);
  const { termsAndConditions_TEXT } = language;
  const { service_TEXT, privacy_TEXT } = termsAndConditions_TEXT;
  const { emailNotValid_TEXT } = language;
  const textColor = useSelector((state) => state.app.ctaTextColor);
  const { handleSubmitNewsletter, isLoading } = useNewsletterController();
  const [data, setData] = useState({ email: '' });
  const [errors, setErrors] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  const { description, title } = userData;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    }

    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignUp = useCallback(() => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email.trim()) {
      newErrors.email = emailNotValid_TEXT;
    } else if (!emailRegex.test(data.email.trim())) {
      newErrors.email = emailNotValid_TEXT;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors)?.length === 0) {
      submitData(data);
    }
  }, [userData, data]);

  const submitData = (passedData) => {
    handleSubmitNewsletter({
      actionId: userData?._id,
      takeActionData: {
        actionType: userData?.actionData?.actionType,
        ...passedData,
      },
    });
  };

  const handleClick = () => {
    window.open('https://www.bridged.media/', '_blank');
  };

  const renderHighlightedTitle = () => {
    const words = title.split(' ');
    const highlightedWords = words.slice(0, 3).join(' ');
    const remainingWords = words.slice(3).join(' ');
    return (
      <h2 style={{ fontSize: '1.35rem', lineHeight: '1.3' }}>
        <span className="highlight">{highlightedWords}</span> {remainingWords}
      </h2>
    );
  };

  return (
    <div className="newsletterLeftAligned">
      <CtaFlipIcon color={textColor} />
      <div
        className="newsletterContent ctaLeftAlignedContent"
        style={{
          color: textColor,
        }}
      >
        <div
          style={{
            alignItems: 'start',
          }}
        >
          {
            <a
              href="https://www.bridged.media/"
              onClick={handleClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/bridged_logo_white.png" alt="Logo" style={{ marginTop: 20 }} />
            </a>
          }
        </div>
        {renderHighlightedTitle()}
        <div className="leftAlignedDescription">
          <p
            style={{
              backgroundColor: textColor === 'white' ? '#00000080' : '#ffffff80',
            }}
            className="description"
          >
            {description}
          </p>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-bewteen',
            gap: 'var(--mpr-3)',
            marginTop: 'var(--mpr-2)',
          }}
        >
          <div className="newsletterCenterInputDiv highlightedBorder">
            <input
              type="email"
              name="email"
              onChange={handleInputChange}
              placeholder="Enter your email address..."
              error={errors.email}
            />
            <Button isLoading={isLoading} onClick={handleSignUp}>
              {isMobile ? (
                <Icon
                  name="ri-send-plane-fill"
                  style={{
                    fontSize: '15px',
                  }}
                  color="white"
                />
              ) : (
                <div style={{ width: '80px' }}>Get in touch</div>
              )}
            </Button>
          </div>
          <div
            className="highlightedBorder"
            style={{
              boxShadow: 'none',
            }}
          >
            <GoogleLogin
              onSubmitHandler={submitData}
              isSmall={true}
              style={{
                width: '45px',
                height: '45px',
              }}
            />
          </div>
        </div>
        <p
          style={{
            alignSelf: 'flex-start',
            height: '2px',
          }}
        >
          {errors.email && <p className="errorText">{errors.email}</p>}
        </p>
        <h6
          style={{
            fontWeight: 500,
            color: 'white',
          }}
        >
          {'Upon submission, I agree to receiving emails, and have read the'}{' '}
          <a
            href="https://www.bridged.media/terms-of-service"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#F9D849' }}
          >
            {service_TEXT}
          </a>{' '}
          &nbsp; & &nbsp;{' '}
          <a
            href="https://www.bridged.media/privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{ color: '#F9D849' }}
          >
            {privacy_TEXT}
          </a>
        </h6>
      </div>
    </div>
  );
}

export default LeftAligned;
