import React from 'react';
import { useSelector } from 'react-redux';
import Content from './Content';

function Default({ data }) {
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  return (
    data && (
      <div className="link">
        <Content data={data} logo={logo} />
      </div>
    )
  );
}

export default Default;
