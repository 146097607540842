import React from 'react';
import '../styles/singlePageOne.css';
import useKnowledgeAgentController from '../controllers/useKnowledgeAgentController';
import { useSelector } from 'react-redux';
import { getColorOpacityPercentage } from '../../../../utils/controllers/colors';
import TopBar from '../components/singlePageOne/TopBar';
import FAQ from '../components/singlePageOne/FAQ';
import Body from '../components/singlePageOne/Body';

function SinglePageOne() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    handeLinkClick,
    handRatingTake,
  } = useKnowledgeAgentController();

  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);

  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);

  const numberOfKaQuestionsSubmitted = useSelector(
    (state) => state.app.numberOfKaQuestionsSubmitted
  );
  const showActionAfterResponses = useSelector(
    (state) => state?.flipcard?.data?.config?.showActionAfterResponses
  );

  const isGatedEngagement = useSelector((state) => state?.flipcard?.data?.config?.gatedEngagement);

  const isShowEmailForm = () => {
    return (
      numberOfKaQuestionsSubmitted > showActionAfterResponses &&
      actions?.length > 0 &&
      showActionAfterResponses &&
      isGatedEngagement &&
      !isCtaTaken
    );
  };

  const isShowBanner = () => {
    return (
      showActionAfterResponses - numberOfKaQuestionsSubmitted >= 0 &&
      actions?.length > 0 &&
      showActionAfterResponses &&
      isGatedEngagement
    );
  };

  return (
    <div className="singlePageOne">
      <TopBar
        bgColorOpacity={bgColorOpacity}
        handleGenerateAnswer={handleGenerateAnswer}
        isLoading={isLoading}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        responseData={responseData}
        isShowEmailForm={isShowEmailForm()}
        isShowBanner={isShowBanner()}
      />

      {responseData?.length === 0 ? (
        <FAQ bgColorOpacity={bgColorOpacity} handleGenerateAnswer={handleGenerateAnswer} />
      ) : (
        <Body
          bgColorOpacity={bgColorOpacity}
          responseData={responseData}
          handRatingTake={handRatingTake}
          handeLinkClick={handeLinkClick}
          inputQuery={inputQuery}
          isLoading={isLoading}
          isShowEmailForm={isShowEmailForm()}
          isShowBanner={isShowBanner()}
          handleGenerateAnswer={handleGenerateAnswer}
          setInputQuery={setInputQuery}
        />
      )}
    </div>
  );
}

export default SinglePageOne;
