import React from 'react';
import { useSelector } from 'react-redux';

function KALogo() {
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);
  const poweredByBridged = useSelector((state) => state.flipcard.data.config?.poweredByBridged);
  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);
  const poweredBy_TEXT = useSelector((state) => state.language.data.poweredBy_TEXT);

  return (
    <div>
      {!logo && !poweredByBridged ? (
        <div></div>
      ) : (
        <div>
          {!logo && <p>{poweredBy_TEXT}</p>}
          <img
            style={{
              height: 'var(--logo-height-small)',
            }}
            onClick={() => {
              if (!logo) {
                window.open('https://bridged.media?utm_source=card', '_blank');
              }
            }}
            src={logo || bridgedLogo}
            alt="Logo"
          />
        </div>
      )}
    </div>
  );
}

export default KALogo;
