import DynamicFormWorkflow from '../../presentation/ctas/forms/workflow/DynamicFormWorkflow';
import LinkWorkflow from '../../presentation/ctas/link/workflow/LinkWorkflow';
import MultimediaWorkflow from '../../presentation/ctas/multimedia/workflow/MultimediaWorkflow';
import NewsletterWorkflow from '../../presentation/ctas/newsletter/workflow/NewsletterWorkflow';
import RegistrationWorkflow from '../../presentation/ctas/registration/workflow/RegistrationWorkflow';

const totalCtaTypes = [
  {
    name: 'Newsletter',
    value: 'newsLetterSignup',
    component: NewsletterWorkflow,
  },
  {
    name: 'Email Collection',
    value: 'emailCollection',
    component: NewsletterWorkflow,
  },
  {
    name: 'Registration',
    value: 'registration',
    component: RegistrationWorkflow,
  },
  {
    name: 'Affiliate Link',
    value: 'link',
    component: LinkWorkflow,
  },
  {
    name: 'Multimedia',
    value: 'multimedia',
    component: MultimediaWorkflow,
  },
  {
    name: 'Dynamic Forms',
    value: 'dynamicForm',
    component: DynamicFormWorkflow,
  },
];

export default totalCtaTypes;
