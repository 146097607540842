import React from 'react';
import Marquee from 'react-fast-marquee';
import { useSelector } from 'react-redux';

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array?.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

const FAQCard = ({ data, bgColorOpacity, handleGenerateAnswer }) => {
  const numberOfKaQuestionsSubmitted = useSelector(
    (state) => state.app.numberOfKaQuestionsSubmitted
  );
  const showActionAfterResponses = useSelector(
    (state) => state?.flipcard?.data?.config?.showActionAfterResponses
  );

  return (
    <div
      className="singlePageOneFAQDiv"
      style={{ backgroundColor: bgColorOpacity }}
      onClick={() => {
        // if (showActionAfterResponses - numberOfKaQuestionsSubmitted === 0) {
        //   return;
        // }
        handleGenerateAnswer(data);
      }}
    >
      <p>{data?.title}</p>
    </div>
  );
};

function FAQ({ bgColorOpacity, handleGenerateAnswer }) {
  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);

  const chunkedQuestions = chunkArray(kaFAQQuestions, 3);

  return (
    <div className="singlePageOneFAQs">
      <div className="singlePageOneFAQsGradient gradient-left"></div>
      <div className="singlePageOneFAQsGradient gradient-right"></div>

      {chunkedQuestions.map((questionRow, rowIndex) => (
        <Marquee key={rowIndex} direction={rowIndex % 2 === 0 ? 'left' : 'right'}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {questionRow.map((data, index) => (
              <FAQCard
                key={index}
                data={data}
                bgColorOpacity={bgColorOpacity}
                handleGenerateAnswer={handleGenerateAnswer}
              />
            ))}
          </div>
        </Marquee>
      ))}
    </div>
  );
}

export default FAQ;
