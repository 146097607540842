import { useEffect, useState } from 'react';
import useMessage from '../../../../utils/presentation/message/controllers/useMessage';
import { useSelector } from 'react-redux';

const useDeaQuerys = () => {
  const language = useSelector((state) => state.language.data);
  const { showMessage } = useMessage();
  const [quieres, setQuieres] = useState(null);

  const saveQuery = (query) => {
    try {
      // Stringify the query object to ensure deep copy
      const queryToSave = JSON.parse(JSON.stringify(query));
      const stringQuery = `I want to know ${queryToSave?.dimension_name} and ${queryToSave?.metric_name?.join(', ')} for the ${queryToSave?.lastNDays} days`;

      // Parse existing queries, handle potential JSON parsing errors
      let existingQueries = [];
      try {
        existingQueries = JSON.parse(localStorage.getItem('deaSavedQueries')) || [];
      } catch (e) {
        console.error('Error parsing saved queries:', e);
        existingQueries = [];
      }

      // Check if query already exists
      const isDuplicate = existingQueries.some(
        (existingQuery) => existingQuery.title === stringQuery
      );

      if (isDuplicate) {
        showMessage('warning', language?.queryAlreadySaved_TEXT);
        return;
      }

      const newQuery = {
        title: stringQuery,
        query: queryToSave,
      };

      existingQueries.push(newQuery);
      localStorage.setItem('deaSavedQueries', JSON.stringify(existingQueries));
      showMessage('success', language?.querySavedSuccessfully_TEXT);
    } catch (error) {
      console.error('Error saving query:', error);
      showMessage('error', 'Failed to save query. Please try again.');
    }
  };

  const deleteQuery = (queryTitle) => {
    try {
      // Parse existing queries, handle potential JSON parsing errors
      let existingQueries = [];
      try {
        existingQueries = JSON.parse(localStorage.getItem('deaSavedQueries')) || [];
      } catch (e) {
        console.error('Error parsing saved queries:', e);
        existingQueries = [];
      }

      const filteredQueries = existingQueries.filter(
        (existingQuery) => existingQuery.title !== queryTitle
      );

      localStorage.setItem('deaSavedQueries', JSON.stringify(filteredQueries));

      const sentData = filteredQueries.map((query) => ({
        title: query.title,
        _id: query.title,
        query: JSON.parse(JSON.stringify(query.query)), // Ensure deep copy of query object
      }));

      setQuieres(sentData);
    } catch (error) {
      console.error('Error deleting query:', error);
      showMessage('error', 'Failed to delete query. Please try again.');
    }
  };

  useEffect(() => {
    try {
      // Parse existing queries, handle potential JSON parsing errors
      let existingQueries = [];
      try {
        existingQueries = JSON.parse(localStorage.getItem('deaSavedQueries')) || [];
      } catch (e) {
        console.error('Error parsing saved queries:', e);
        existingQueries = [];
      }

      const sentData = existingQueries.map((query) => ({
        title: query.title,
        _id: query.title,
        query: JSON.parse(JSON.stringify(query.query)), // Ensure deep copy of query object
      }));

      setQuieres(sentData);
    } catch (error) {
      console.error('Error loading saved queries:', error);
      setQuieres([]);
    }
  }, []);

  return { quieres, setQuieres, saveQuery, deleteQuery };
};

export default useDeaQuerys;
