import React, { useCallback, useState } from 'react';
import Input from '../../../../../utils/components/Input';
import { useSelector } from 'react-redux';
import Button from '../../../../../utils/components/Button';
import Icon from '../../../../../utils/components/Icon';

function TwoInput({ handleGenerateAnswer, inputQuery, setInputQuery, isLoading }) {
  const enterQuestion = useSelector((state) => state.language.data.enterQuestion);

  const handleGenerate = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      const newErrors = {};

      if (!inputQuery.trim()) {
        newErrors.inputQuery = 'Please enter a question.';
      }

      if (Object.keys(newErrors)?.length === 0) {
        handleGenerateAnswer();
      }
    },
    [inputQuery, handleGenerateAnswer]
  );

  return (
    <form onSubmit={handleGenerate} className="kaInputDivTwo">
      <Input
        placeholder={enterQuestion}
        value={inputQuery}
        onChange={(e) => {
          setInputQuery(e.target.value);
        }}
        required={true}
      />

      <Button isLoading={isLoading} onClick={handleGenerate}>
        <Icon name="ri-arrow-up-line" style={{ fontSize: '17px' }} color="white" />
      </Button>
    </form>
  );
}

export default TwoInput;
