import { createSlice } from '@reduxjs/toolkit';
import { US_Language } from '../../constants/flipcard/languageState';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    data: US_Language,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
