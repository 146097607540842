import React from 'react';
import KAFooter from '../KAFooter';
import { useSelector } from 'react-redux';
import KABody from '../KABody/KABody';
import OneFAQ from '../One/OneFAQ';

function TwoBody({
  responseData,
  handleGenerateAnswer,
  handeLinkClick,
  handRatingTake,
  isMobileView,
}) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);

  return (
    <>
      {!isRagCollapsed && (
        <>
          <OneFAQ
            responseDataLength={responseData?.length}
            handleGenerateAnswer={handleGenerateAnswer}
          />
          <KABody
            responseData={responseData}
            handeLinkClick={handeLinkClick}
            handRatingTake={handRatingTake}
            isMobileView={isMobileView}
          />
        </>
      )}
      <KAFooter />
    </>
  );
}

export default TwoBody;
