import React from 'react';

function KaInsights({ insights }) {
  if (insights?.length === 0 || !insights) {
    return <></>;
  }

  return (
    <div>
      <h4 className="kaAnswerHeading" style={{ marginBottom: 'var(--mpr-3)' }}>
        Looking to evolve the results?
      </h4>

      {insights?.map((insight, index) => {
        return (
          <ul
            key={index}
            style={{
              paddingLeft: 'var(--mpr-1-2)',
              marginBottom: 'var(--mpr-1-2)',
            }}
          >
            <li>
              <span>Recommendation {index + 1}: </span> {insight?.title}
            </li>
            <ul
              style={{
                paddingLeft: 'var(--mpr-1)',
                display: 'grid',
                gap: 'var(--mpr-mini)',
                marginTop: 'var(--mpr-mini)',
              }}
            >
              <li>{insight?.insight}</li>
              <li>{insight?.recommendation}</li>
            </ul>
          </ul>
        );
      })}
    </div>
  );
}

export default KaInsights;
