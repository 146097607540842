import { useDispatch, useSelector } from 'react-redux';
import useEngController from '../../reusable/controllers/useEngController';
import { setIsShowEngResult } from '../../../../redux/slices/appSlice';
import { useEffect } from 'react';

const optionsAlphabet = ['A', 'B', 'C', 'D'];

function Body() {
  const dispatch = useDispatch();
  const { handleEngTake, calculateWidthPercentage, selectedId } = useEngController();

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isShowEngResult = useSelector((state) => state.app.isShowEngResult);
  const gatedEngagementConfig = useSelector(
    (state) => state?.flipcard?.data?.config?.gatedEngagement
  );
  const engagement = useSelector((state) => state.flipcard.data.flipCard.engagement);
  const options = engagement?.engagementData?.options;
  const optionsData = engagement?.optionsData;

  const handleOptionClicked = (optionId) => {
    if (!selectedId) {
      dispatch(setIsShowEngResult(!gatedEngagementConfig));
      handleEngTake(optionId);
    }
  };

  useEffect(() => {
    dispatch(setIsShowEngResult(isCtaTaken));
  }, [isCtaTaken]);

  return (
    <div className="engInputMainDiv">
      {options?.map((data, index) => {
        const isSelected = data._id === selectedId;

        const takesCount = isSelected
          ? optionsData[index].takesCount + 1
          : optionsData[index].takesCount;

        const dynamicWidth = calculateWidthPercentage(takesCount);

        const isAnswer = data.isAnswer;
        const selectedStyle = isSelected && 'pollSelected';

        const invalidProgressBarStyle = {
          width: isShowEngResult && isSelected ? '100%' : '0%',
          visibility: isShowEngResult && selectedId ? 'visible' : 'hidden',
          backgroundColor: '#d24847',
        };

        const validProgressBarStyle = {
          width: isShowEngResult && selectedId && isAnswer ? '100%' : '0%',
          visibility: isShowEngResult && selectedId ? 'visible' : 'hidden',
          backgroundColor: '#66bb6a',
        };

        const dynamicProgressBarStyle = {
          width: isShowEngResult && selectedId ? dynamicWidth + '%' : '0%',
        };

        return (
          <div
            key={index}
            onClick={() => handleOptionClicked(data._id)}
            className={`engInputBar ${isShowEngResult && selectedStyle} ${
              isShowEngResult && selectedId && isAnswer ? 'pollSelected' : ''
            }`}
          >
            <div className="engBgProgressBar" style={invalidProgressBarStyle} />
            <div className="engBgProgressBar" style={validProgressBarStyle} />
            <div className="engBgProgressBar" style={dynamicProgressBarStyle} />

            <div className="engInputBarContent">
              <div className="engInputBarContentData">
                <h5>{optionsAlphabet[index]}</h5>
                <h5 className="engInputText">{data.title}</h5>
              </div>
              {isShowEngResult && <h5>{dynamicWidth?.toFixed(0)}%</h5>}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Body;
