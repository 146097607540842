import React from 'react';
import '../style/chatLoader.css';

function ChatLoader() {
  return (
    <div className="chatBotLoaderContainer">
      <div className="chatBotLoader"></div>
    </div>
  );
}

export default ChatLoader;
