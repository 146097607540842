import React, { useEffect, useRef } from 'react';
import { WithSeeMore } from 'react-insta-stories';
import Icon from '../../../../utils/components/Icon';

export function ImageContainer({ action, story, src }) {
  return (
    <WithSeeMore story={story} action={action}>
      <div
        className="multimediaStory"
        style={{
          backgroundImage: `url(${src})`,
        }}
      />
    </WithSeeMore>
  );
}

export function VideoContainer({ action, story, src, isPaused }) {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (isPaused) {
      video.pause();
    } else {
      video.play();
    }
  }, [isPaused]);

  return (
    <WithSeeMore story={story} action={action}>
      <div className="multimediaStory">
        <video ref={videoRef} playsInline autoPlay muted loop>
          <source src={src} type="video/mp4" />
        </video>
      </div>
    </WithSeeMore>
  );
}

export function SeeMoreContainer({ close }) {
  return (
    <div className="multimediaSeeMore">
      <div onClick={close} className="multimediaCloseIcon">
        <Icon name="ri-close-line" />
      </div>
    </div>
  );
}

export function SeeMoreRedirectLink({ linkSrc }) {
  return (
    <div className="multimediaSeeMoreCollapsed">
      <a href={linkSrc} target="_blank" rel="noreferrer">
        <Icon name="ri-arrow-up-s-line" />
        <span>See More</span>
      </a>
    </div>
  );
}
