import React from 'react';
import CurrentXrayWorkflow from '../../../workflow/CurrentXrayWorkflow';
import { useSelector } from 'react-redux';

function InlineAccordian({ allOptions, onSelectedOptionHandler, selectedOption }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);

  return (
    <div className="xrayInlineMobileQuestions">
      <div className="moreQuestions">
        <div className="moreQuestion" style={{ marginTop: '0px' }}>
          {allOptions?.map((data, index) => {
            const isActive = data?.type === selectedOption?.type;
            return (
              <>
                <div
                  key={index}
                  className={` xrayQuestion ${isActive ? 'ActiveMoreQuestion' : ''}`}
                  onClick={() => {
                    onSelectedOptionHandler(data);
                  }}
                >
                  {language === 'he' ? (
                    <>
                      <span>
                        {isActive ? (
                          <i className="ri-arrow-up-s-line"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line"></i>
                        )}
                      </span>
                      <span className="xrayMoreQuestionText">{data?.questionText}</span>
                    </>
                  ) : (
                    <>
                      <span className="xrayMoreQuestionText">{data?.questionText}</span>

                      <span>
                        {isActive ? (
                          <i className="ri-arrow-up-s-line"></i>
                        ) : (
                          <i className="ri-arrow-down-s-line"></i>
                        )}
                      </span>
                    </>
                  )}
                </div>

                <div
                  className={`xrayInlineAnswer ${
                    isActive ? 'xrayInlineAnswerActive customScrollBar' : 'xrayInlineAnswerDeactive'
                  }`}
                >
                  <CurrentXrayWorkflow currentEngData={selectedOption} />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default InlineAccordian;
