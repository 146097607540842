import { useEffect, useState } from 'react';
import useCtaViewHandler from '../../../ctas/reusable/controllers/useCtaViewHandler';
import useLinkController from '../../../ctas/link/controllers/useLinkController';
import { useDispatch, useSelector } from 'react-redux';
import { flipTheCard } from '../../../../redux/slices/appSlice';

const useAdvertisementHandler = () => {
  const dispatch = useDispatch();
  const [cta, setCta] = useState({});
  const { handleCtaView } = useCtaViewHandler();
  const { handleClickLink } = useLinkController();
  const actions = useSelector((state) => state.flipcard.data.flipCard.actions);
  const buttonLink = cta?.actionData?.buttonLink;

  useEffect(() => {
    if (actions?.length > 0) {
      setCta(actions[0]);
    }
  }, [actions]);

  const handleClick = () => {
    if (cta?.actionData?.actionType === 'link') {
      handleLinkClick();
    } else {
      handleFlip();
    }
  };

  const handleLinkClick = () => {
    handleCtaView(cta?._id);
    handleClickLink({
      actionId: cta?._id,
      takeActionData: {
        actionType: 'link',
      },
    });
    window.open(buttonLink, '_blank');
  };

  const handleFlip = () => {
    dispatch(flipTheCard());
  };

  return { cta, handleClick };
};

export default useAdvertisementHandler;
