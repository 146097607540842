import usePostMessage from './usePostMessage';

const useTriggerEngagementCtaTake = () => {
  const { postMessage } = usePostMessage();
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const triggerActionTake = () => {
    postMessage({
      event: 'action_take',
      trackerId: getQueryParam('trackerId'),
    });
  };

  const triggerEngagementTake = () => {
    postMessage({
      event: 'engagement_take',
      trackerId: getQueryParam('trackerId'),
    });
  };

  return { triggerActionTake, triggerEngagementTake };
};

export default useTriggerEngagementCtaTake;
