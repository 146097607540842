import React, { useState, useEffect, useRef } from 'react';
import '../../styles/default.css';
import XRayTopBar from './XRayTopBar';
import CurrentXrayWorkflow from '../../workflow/CurrentXrayWorkflow';
import MoreQuestions from '../MoreQuestions';
import CtaWorkflow from '../../../../ctas/workflow/CtaWorkflow';
import useXrayDefault from '../../controllers/useXrayDefault';
import { useSelector } from 'react-redux';
import GoogleAdsWorkflow from '../../../../common/googleAds/workflow/GoogleAdsWorkflow';
import ScrollAnimation from '../../../../../utils/components/scrollAnimation/ScrollAnimation';
import Refinery89Ads from '../../../../common/googleAds/components/Refinery89Ads';
import Feedback from '../../../reusable/components/feedbacks/Feedback';
import PoweredBy from '../../../reusable/components/poweredBy/PoweredBy';

function Default() {
  const showGoogleAd = useSelector(
    (state) => state.flipcard.data?.config?.googleAdConfig?.isActive
  );

  const showRefineryAdConfig = useSelector(
    (state) => state.flipcard.data?.config?.refineryAdConfig?.isActive
  );

  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);

  const {
    engagementData,
    isLinkCta,
    remainingData,
    ctaIndex,
    handlePopEngagementData,
    handleAddEngagementData,
    isShowCta,
  } = useXrayDefault();

  const [isVisible, setIsVisible] = useState(false);
  const xrayScrollableBodyRef = useRef(null);

  useEffect(() => {
    if (xrayScrollableBodyRef.current) {
      xrayScrollableBodyRef.current.scrollTop = 0;
    }
  }, [engagementData]);

  useEffect(() => {
    if (!isXrayCollapsed) {
      setTimeout(() => {
        function handleScroll() {
          if (xrayScrollableBodyRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = xrayScrollableBodyRef.current;
            const isScrollable = scrollHeight > clientHeight;
            if (engagementData?.length < 2) {
              if (isScrollable) {
                setIsVisible(scrollTop < 20);
              }
            } else {
              setIsVisible(false);
            }
          }
        }

        handleScroll();

        if (xrayScrollableBodyRef.current) {
          xrayScrollableBodyRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
          if (xrayScrollableBodyRef.current) {
            xrayScrollableBodyRef.current.removeEventListener('scroll', handleScroll);
          }
        };
      }, 500);
    }
  }, [xrayScrollableBodyRef, isXrayCollapsed, engagementData]);

  return (
    <div className="xray">
      <XRayTopBar
        currentEngData={engagementData[engagementData?.length - 1]}
        totalEngagements={engagementData?.length}
        handlePopEngagementData={handlePopEngagementData}
        isShowCta={isShowCta()}
      />

      <div
        ref={xrayScrollableBodyRef}
        className={`xrayScrollableBody customScrollBar ${isShowCta() && 'xrayBgBlur'}`}
      >
        <CurrentXrayWorkflow
          currentEngData={engagementData[engagementData?.length - 1]}
          isPadding={true}
        />
        <div className={`${isShowCta() && 'xrayBgBlur'} `}>
          <ScrollAnimation isVisible={isVisible} />
        </div>
        {!isLinkCta && (
          <MoreQuestions
            remainingData={remainingData}
            handleAddEngagementData={handleAddEngagementData}
          />
        )}

        <div
          style={{
            padding: '0px var(--mpr-1-2)',
            paddingBottom: 'var(--mpr-1-2)',
          }}
        >
          <Feedback isShowCta={isShowCta()} />
        </div>

        {showGoogleAd && (
          <div
            style={{
              marginTop: 'var(--mpr-2)',
            }}
            className={`${isShowCta() && 'xrayBgBlur'} googleAdsParent`}
          >
            <GoogleAdsWorkflow />
          </div>
        )}
        {showRefineryAdConfig && <Refinery89Ads />}
      </div>

      {isShowCta() && (
        <div className="gatedCtaOverlay xrayOverlay">
          <CtaWorkflow actionIndex={ctaIndex} isTransparent={true} />
        </div>
      )}

      <PoweredBy isShowCta={isShowCta()} />
    </div>
  );
}

export default Default;
