import html2canvas from 'html2canvas';

const getFlipcardScreenShot = async (isFlipped, isEndScreen) => {
  const isBackClassSwitcher = () => {
    if (isEndScreen) {
      return '.endScreen';
    } else {
      return '.cta';
    }
  };

  const flipcard = document.querySelector(isFlipped ? isBackClassSwitcher() : '.flipCardFront');

  if (flipcard) {
    try {
      const canvas = await html2canvas(flipcard, {
        backgroundColor: 'none',
        logging: true,
        useCORS: true,
      });
      const base64Image = canvas.toDataURL('image/png');
      return base64Image;
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      return null;
    }
  }

  return null;
};

export default getFlipcardScreenShot;
