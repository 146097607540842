import React from 'react';
import CarouselOne from './CarouselOne';
import { useSelector } from 'react-redux';
import CarouselTwo from './CarouselTwo';
import CarouselThree from './CarouselThree';

function CtaCarouselWorkflow() {
  const carouselTemplate = useSelector((state) => state.flipcard.data.config?.carouselTemplate);

  if (carouselTemplate === 'two') {
    return <CarouselTwo />;
  }

  if (carouselTemplate === 'three') {
    return <CarouselThree />;
  }

  return <CarouselOne />;
}

export default CtaCarouselWorkflow;
