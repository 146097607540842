import FlipcardWorkflow from './presentation/flipcard/workflow/FlipcardWorkflow';
import ErrorWrapper from './utils/presentation/error/workflow/ErrorWrapper';

function App() {
  return (
    <ErrorWrapper>
      <FlipcardWorkflow />
    </ErrorWrapper>
  );
}

export default App;
