import React from 'react';
import Icon from '../../../utils/components/Icon';
import { useSelector } from 'react-redux';
import { useShareButtonClickedMutation } from '../../../services/api';
import useMessage from '../../../utils/presentation/message/controllers/useMessage';

function SocialIcons({ endScreenId }) {
  // Select necessary data from Redux store
  const copiedToClipBoardText = useSelector((state) => state.language.data.copiedToClipBoard_TEXT);
  const configData = useSelector((state) => state?.flipcard?.data?.config);
  const [_SHARE] = useShareButtonClickedMutation();
  const { showMessage } = useMessage();

  // Function to handle click on social icons
  const onClickHandler = async (data) => {
    if (data?.name === 'ri-link') {
      navigator.clipboard.writeText(data?.url);
      showMessage('success', copiedToClipBoardText);
    } else {
      window.open(data.url, '_blank');
    }
    await handleShareClick(data?.name);
  };

  // Function to handle share button clicked mutation
  const handleShareClick = async (name) => {
    const shareData = {
      endScreenId: endScreenId,
      shareType: name,
    };
    await _SHARE(shareData);
  };

  // Function to generate share text
  const getShareText = () => {
    const shareUrl = configData?.pageURL;
    const shareTitle = configData?.pageTitle;
    return `${shareUrl}\n${shareTitle}\n`;
  };

  // Data for social icons
  const socialsData = [
    {
      name: 'ri-link',
      url: configData?.pageURL,
    },
    {
      name: 'ri-whatsapp-line',
      url: `https://wa.me/?text=${encodeURIComponent(getShareText())}`,
    },
    {
      name: 'ri-facebook-fill',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        configData?.pageURL
      )}`,
    },
    {
      name: 'ri-twitter-x-fill',
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        configData?.pageURL
      )}&text=${encodeURIComponent(configData?.pageTitle)}`,
    },
    {
      name: 'ri-mail-line',
      url: `mailto:info@example.com?&subject=&cc=&bcc=&body=${encodeURIComponent(getShareText())}`,
    },
  ];

  return (
    <div className="endScreenSocials">
      {socialsData.map((data, index) => (
        <div key={index} onClick={() => onClickHandler(data)}>
          <Icon name={data.name} size="medium" color="var(--primary-Color)" />
        </div>
      ))}
    </div>
  );
}

export default SocialIcons;
