import React from 'react';
import '../styles/poll.css';
import TopBar from '../../reusable/components/TopBar';
import Body from '../components/Body';
import { useSelector } from 'react-redux';
import EngagementFooter from '../../reusable/components/footers/EngagementFooter';

function PollWorkflow() {
  const disclaimer = useSelector((state) => state.flipcard.data.config.disclaimer);

  return (
    <div className="poll">
      <TopBar />
      <Body />
      <EngagementFooter />
      {disclaimer && (
        <div className="pollDisclaimer">
          <h5>{disclaimer}</h5>
        </div>
      )}
    </div>
  );
}

export default PollWorkflow;
