import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportKAReportPdf = (data) => {
  const { data_table, insights, question } = data;
  const doc = new jsPDF();

  // Reduce the font size
  doc.setFontSize(10);

  let linePosition = 10; // Start at the top of the page

  if (question) {
    // Add the question with reduced spacing
    doc.setFont('helvetica', 'bold');
    doc.text('Question:', 10, linePosition);
    doc.setFont('helvetica', 'normal');
    doc.text(question, 10, linePosition + 5);
    linePosition += 15; // Move down after the question
  }

  // Add dynamic insights with bold titles, bullet points for insights and recommendations
  insights.forEach(({ title, insight, recommendation }, index) => {
    doc.setFont('helvetica', 'bold');
    doc.text(`Recommendation ${index + 1}: ${title}`, 10, linePosition);
    linePosition += 6; // Reduced space after title

    doc.setFont('helvetica', 'normal');

    // Add bullet point before insight
    let wrappedInsight = doc.splitTextToSize(`• ${insight}`, 180);
    doc.text(wrappedInsight, 10, linePosition);
    linePosition += wrappedInsight.length * 5;

    // Add bullet point before recommendation
    let wrappedRecommendation = doc.splitTextToSize(`• ${recommendation}`, 180);
    doc.text(wrappedRecommendation, 10, linePosition);
    linePosition += wrappedRecommendation.length * 5 + 6; // Reduced space after recommendation
  });

  // Extract columns and rows from data_table
  const columns = data_table.columns.map((col) => col.title);
  const rows = data_table.tableData.map((row) =>
    data_table.columns.map((col) => row[col.dataIndex])
  );

  // Add the table dynamically with proper column width and adjusted font size
  doc.autoTable({
    head: [columns],
    body: rows,
    startY: linePosition + 5, // Slightly reduced margin between insights and table
    styles: { fontSize: 9 },
    margin: { right: 10, left: 10 },
  });

  // Save the PDF
  doc.save('Report.pdf');
};
