import { useDispatch, useSelector } from 'react-redux';
import useEngController from '../../reusable/controllers/useEngController';
import { useEffect } from 'react';
import { setIsShowEngResult } from '../../../../redux/slices/appSlice';
const optionsAlphabet = ['A', 'B', 'C', 'D'];

function Body() {
  const dispatch = useDispatch();
  const { handleEngTake, calculateWidthPercentage, selectedId } = useEngController();

  const isCtaTaken = useSelector((state) => state.app.isCtaTaken);
  const isShowEngResult = useSelector((state) => state.app.isShowEngResult);
  const isGatedEngagement = useSelector((state) => state?.flipcard?.data?.config?.gatedEngagement);

  const engagement = useSelector((state) => state.flipcard.data.flipCard.engagement);

  const options = engagement?.engagementData?.options;
  const optionsData = engagement?.optionsData;

  const handleOptionClicked = (optionId) => {
    if (!selectedId) {
      dispatch(setIsShowEngResult(!isGatedEngagement));
      handleEngTake(optionId);
    }
  };

  useEffect(() => {
    dispatch(setIsShowEngResult(isCtaTaken));
  }, [isCtaTaken]);

  return (
    <div className="engInputMainDiv">
      {options?.map((data, index) => {
        const isSelected = data._id === selectedId;

        const takesCount = isSelected
          ? optionsData[index].takesCount + 1
          : optionsData[index].takesCount;

        const dynamicWidth = calculateWidthPercentage(takesCount);

        const progressBarStyle = {
          width: isShowEngResult && isSelected ? '100%' : '0%',
          visibility: isShowEngResult && selectedId ? 'visible' : 'hidden',
          backgroundColor: 'var(--primary-Color)',
        };

        const dynamicProgressBarStyle = {
          width: isShowEngResult && selectedId ? dynamicWidth + '%' : '0%',
        };

        return (
          <div
            key={index}
            onClick={() => handleOptionClicked(data._id)}
            className={`engInputBar ${isShowEngResult && isSelected && 'pollSelected'}`}
          >
            <div className="engBgProgressBar" style={progressBarStyle} />
            <div className="engBgProgressBar" style={dynamicProgressBarStyle} />

            <div className="engInputBarContent">
              <div className="engInputBarContentData">
                <h5>{optionsAlphabet[index]}</h5>
                <h5 className="engInputText">{data.title}</h5>
              </div>
              {isShowEngResult && <h5>{dynamicWidth?.toFixed(0)}%</h5>}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Body;
