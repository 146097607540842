import React from 'react';
import useXrayInline from '../../../controllers/useXrayInline';
import CurrentXrayWorkflow from '../../../workflow/CurrentXrayWorkflow';
import GoogleAdsWorkflow from '../../../../../common/googleAds/workflow/GoogleAdsWorkflow';
import Refinery89Ads from '../../../../../common/googleAds/components/Refinery89Ads';
import CtaWorkflow from '../../../../../ctas/workflow/CtaWorkflow';
import Feedback from '../../../../reusable/components/feedbacks/Feedback';
import PoweredBy from '../../../../reusable/components/poweredBy/PoweredBy';
import { useSelector } from 'react-redux';
import FourInlineTopBar from '../../../components/four/FourInlineTopBar';
import FourInlineQuestions from '../../../components/four/FourInlineQuestions';
import '../../../styles/four/fourInlineMobile.css';

function FourInlineMobile() {
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);

  const {
    allOptions,
    selectedOption,
    ctaIndex,
    isShowCta,
    onSelectedOptionHandler,
    handleCtaBackButton,
  } = useXrayInline();

  return (
    <div
      className="xray"
      style={{
        backgroundColor: '#fcfbfb',
      }}
    >
      <FourInlineTopBar
        isShowCta={isShowCta()}
        handleCtaBackButton={handleCtaBackButton}
        isDesktop={true}
        selectedOption={selectedOption}
        allOptions={allOptions}
      />

      {!isXrayCollapsed && (
        <>
          <div
            className={`xrayInlineMobileFour xrayInlineMobileBodyFour xrayInlineDesktopBody customScrollBar ${
              isShowCta() && 'xrayBgBlur'
            }`}
          >
            <FourInlineQuestions
              allOptions={allOptions}
              selectedOption={selectedOption}
              onSelectedOptionHandler={onSelectedOptionHandler}
            />
            <div className="xrayInlineMobileAnswersFour">
              <CurrentXrayWorkflow currentEngData={selectedOption} />
            </div>
          </div>
          <div>
            <Feedback isShowCta={isShowCta()} />
          </div>
        </>
      )}

      {isShowCta() && (
        <div className="gatedCtaOverlay xrayOverlayInlineDesktopFour">
          <CtaWorkflow actionIndex={ctaIndex} isTransparent={true} />
        </div>
      )}

      <PoweredBy isShowCta={isShowCta()} />
    </div>
  );
}

export default FourInlineMobile;
