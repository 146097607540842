import React, { useState } from 'react';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';
import ChatLoader from '../../../../../utils/presentation/chatLoader/workflow/ChatLoader';
import { getColorOpacityPercentage } from '../../../../../utils/controllers/colors';
import StarRatings from 'react-star-ratings';

function KAInlineFAQChatBot({ data, responseAnswer, handeLinkClick, handRatingTake, index }) {
  const feedbackConfig = useSelector((state) => state.flipcard.data.config?.feedbackConfig);
  const referenceArticle = useSelector((state) => state.language.data?.referenceArticle);
  const [isReferenceHide, setIsReferenceHide] = useState(false);
  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);

  return (
    <div className="kaAnswers">
      <div
        className="kaAnswersQuestion"
        style={{
          backgroundColor: getColorOpacityPercentage(primaryColor, 0.07),
          color: primaryColor,
        }}
      >
        <h3 style={{ fontSize: '0.9rem' }}>{data?.question}</h3>
      </div>
      {data?.isLoading ? (
        <div style={{ marginBottom: '15px' }}>
          <ChatLoader />
        </div>
      ) : (
        <div
          className="kaAnswersAnswer"
          style={{
            backgroundColor: '#f9f9f9',
          }}
        >
          <Markdown
            className="kaAnswerMainDiv"
            components={{
              a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />,
            }}
          >
            {responseAnswer}
          </Markdown>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            {data?.reference?.length > 0 ? (
              <p
                style={{
                  margin: 'var(--mpr-3) 0px',
                  color: primaryColor,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: 10,
                  cursor: 'pointer',
                  width: 'fit-content',
                }}
                onClick={() => {
                  setIsReferenceHide(!isReferenceHide);
                }}
              >
                {referenceArticle}
                {isReferenceHide ? (
                  <svg
                    width="12"
                    height="6"
                    viewBox="0 0 12 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L9.25 2.763C7.86 4.169 7.16006 4.87201 6.31006 4.98001C6.10427 5.00698 5.89573 5.00698 5.68994 4.98001C4.83994 4.87201 4.14 4.169 2.75 2.763L1 1"
                      stroke={primaryColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 12 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.00024L2.75 3.23723C4.14 1.83123 4.83994 1.12822 5.68994 1.02022C5.89573 0.993259 6.10427 0.993259 6.31006 1.02022C7.16006 1.12822 7.86 1.83123 9.25 3.23723L11 5.00024"
                      stroke={primaryColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
              </p>
            ) : (
              <div></div>
            )}

            <div>
              {feedbackConfig?.isActive && (
                <StarRatings
                  rating={data?.feedback?.rating}
                  starRatedColor="#ffa106"
                  changeRating={(e) => {
                    if (!data?.feedback?.isTaken) {
                      handRatingTake(e, index);
                    }
                  }}
                  numberOfStars={5}
                  name="rating"
                  starDimension="15px"
                  starSpacing="1px"
                  starHoverColor="#ffa106"
                />
              )}
            </div>
          </div>

          {data?.reference?.length > 0 ? (
            <>
              {!isReferenceHide && (
                <div className="kaReference">
                  <ul>
                    {data?.reference?.map((link, index) => {
                      return (
                        <li key={index}>
                          <a
                            href={link?.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                              handeLinkClick(link?.actionId);
                            }}
                          >
                            {link?.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default KAInlineFAQChatBot;
