import React from 'react';
import GoogleAdsWorkflow from '../../../common/googleAds/workflow/GoogleAdsWorkflow';
import { useSelector } from 'react-redux';

function KeyPeople({ data }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);

  return (
    <div>
      <ul
        style={{
          direction: language === 'he' ? 'rtl' : 'ltr',
          paddingRight: language === 'he' ? 'var(--mpr-2)' : '0px',
        }}
      >
        {data?.people.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    </div>
  );
}

export default KeyPeople;
