import { useSelector } from 'react-redux';
import InliFAQInputForm from './InliFAQInputForm';

function InlineFAQInput({ handleGenerateAnswer, inputQuery, setInputQuery, isLoading }) {
  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  return (
    <div className="inlineFAQBottomChat">
      <InliFAQInputForm
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
      />
      {footer?.isActive && (
        <div className="kaFooter">
          <h5
            dangerouslySetInnerHTML={{
              __html: footer?.title,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default InlineFAQInput;
