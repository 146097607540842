import React from 'react';
import Login from '../components/Login';
import { useSelector } from 'react-redux';

function GoogleLogin(props) {
  const googleSigninRequired = useSelector(
    (state) => state?.flipcard?.data?.config?.googleSigninRequired
  );

  if (googleSigninRequired) {
    return <Login {...props} />;
  }

  return <div />;
}

export default GoogleLogin;
