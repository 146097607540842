import { useSelector } from 'react-redux';
import { useSendErrorMutation } from '../../../../services/api';

function extractDomain(url) {
  let domain = url?.replace(/(https?:\/\/)?(www\.)?/, '');
  domain = domain?.split('/')[0];
  return domain;
}

const useErrorHandler = () => {
  // Redux selector to get flipcard data
  const flipcard = useSelector((state) => state.flipcard.data);
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const [_POST_ERROR] = useSendErrorMutation();

  const postError = async (message, customObj, type) => {
    if (mode === 'create') {
      return;
    }

    if (!getQueryParam('trackerId')) {
      return;
    }

    const stackValue = flipcard ? JSON.stringify(flipcard) : 'unknown stack';
    const custom = customObj ? JSON.stringify(customObj) : '';
    const url = flipcard?.config?.pageURL || getQueryParam('pageURL');

    // Sending error to backend
    await _POST_ERROR({
      message: message || 'unknown exception',
      messageString: message || 'unknown exception',
      sessionId: flipcard?.config?.sessionId || 'unknown session',
      url: url || 'unknown url',
      domain: extractDomain(url) || 'unknown domain',
      stack: custom || stackValue,
      type: type || 'CRITICAL',
      trackerId: getQueryParam('trackerId') || 'unknown tracker id',
    });
  };

  return { postError };
};

export default useErrorHandler;
