import React from 'react';
import useKnowledgeAgentController from '../controllers/useKnowledgeAgentController';
import TwoTopBar from '../components/Two/TwoTopBar';
import TwoBody from '../components/Two/TwoBody';

function Two() {
  const {
    responseData,
    handleGenerateAnswer,
    inputQuery,
    setInputQuery,
    isLoading,
    error,
    handeLinkClick,
    handRatingTake,
    isMobileView,
  } = useKnowledgeAgentController();

  return (
    <div className="kaTwo">
      <TwoTopBar
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
        error={error}
      />
      <TwoBody
        responseData={responseData}
        handleGenerateAnswer={handleGenerateAnswer}
        handeLinkClick={handeLinkClick}
        handRatingTake={handRatingTake}
        isMobileView={isMobileView}
      />
    </div>
  );
}

export default Two;
