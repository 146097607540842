import React from 'react';
import { useSelector } from 'react-redux';
import useThemeHandler from '../utils/controllers/useThemeHandler';

function FlipcardLayout({ children }) {
  useThemeHandler();
  const isDarkTheme = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.darkTheme);

  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);

  return (
    <div
      className={`flipcardLayout ${isDarkTheme ? 'darkTheme' : ''}`}
      style={{
        borderRadius: outerBorderRadius,
      }}
    >
      {children}
    </div>
  );
}

export default FlipcardLayout;
