import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../utils/components/Checkbox';

function TermsAndConditions({ onChange, error, isRequired }) {
  // Memoized selector to avoid unnecessary re-renders
  const termsConfig = useSelector((state) => state.flipcard.data.config.termsAndConditions);

  const { title = '', url = '', isActive = false } = termsConfig || {};

  const textLink = useMemo(
    () => (
      <span>
        {title}
        {url && (
          <a href={url} target="_blank" rel="noreferrer" style={{ marginLeft: 'var(--mpr-mini)' }}>
            <i className="ri-external-link-line" style={{ fontSize: '18px' }} />
          </a>
        )}
      </span>
    ),
    [title, url]
  );

  // Return null if terms and conditions are not active
  if (!isActive) return null;

  return (
    <div className="newsletterTermsAndConditions">
      <Checkbox text={textLink} onChange={onChange} error={error} isRequired={isRequired} />
    </div>
  );
}

export default TermsAndConditions;
