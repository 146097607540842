import { useDispatch, useSelector } from 'react-redux';
import usePostMessage from '../../../../../utils/controllers/usePostMessage';
import Icon from '../../../../../utils/components/Icon';
import { setIsXrayCollapsed } from '../../../../../redux/slices/appSlice';
import { useEffect } from 'react';
import getTextColor from '../../../../../utils/controllers/getTextColor';

function FourInlineTopBar({
  isShowCta,
  handleCtaBackButton,
  isDesktop,
  selectedOption,
  allOptions,
}) {
  const headingText = useSelector((state) => state.flipcard.data.flipCard.engagement.title);

  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const backgroundColor = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData.backgroundColor
  );
  const { postMessage } = usePostMessage();
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const backIcon = () => {
    return (
      <Icon
        name="ri-arrow-left-s-line"
        style={{
          marginLeft: 'var(--mpr-mini)',
          cursor: 'pointer',
          zIndex: 200,
        }}
        onClick={handleCtaBackButton}
      />
    );
  };

  const closeIcon = () => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-arrow-down-s-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            opacity: 0.8,
            cursor: 'pointer',
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-arrow-down-s-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            opacity: 0.8,
            cursor: 'pointer',
            transform: 'rotate(180deg)',
          }}
        />
      );
    }
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: 'toggleCollapsion',
      trackerId: getQueryParam('trackerId'),
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: backgroundColor,
        paddingTop: isDesktop ? '14px' : '14px',
        paddingBottom: isDesktop ? '14px' : '14px',
        color: backgroundColor ? getTextColor(backgroundColor) : 'var(--text-Color)',
        border: 'none',
      }}
      className={`engTopbarLogoDiv xrayTopBarInline`}
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      {isShowCta && backIcon()}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <img
          className={`${isShowCta && 'xrayBgBlur'}`}
          alt="Logo"
          src="/ai_icon_four.svg"
          style={{ height: '25px' }}
        />
        <p
          style={{
            fontSize: '1rem',
            opacity: 1,
            marginTop: '3px',
            marginLeft: '10px',
            fontWeight: 'bold',
          }}
          className="firstLine"
        >
          {headingText || selectedOption?.questionText || allOptions[0]?.questionText || ''}
        </p>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          gap: 5,
        }}
        className={`${isShowCta && 'xrayBgBlur'}`}
      >
        {closeIcon()}
      </div>
    </div>
  );
}

export default FourInlineTopBar;
