import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/ChevronRight.svg';
function FooterOne() {
  const footer = useSelector((state) => state.flipcard.data.config?.footer);

  if (footer?.isActive) {
    return (
      <div className="carouselTwoCta">
        <span
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{
            __html: footer?.title,
          }}
        />
        <ChevronRight
          style={{
            width: '16px',
            height: '16px',
            color: 'var(--primary-Color)',
          }}
        />
      </div>
    );
  }

  return <div />;
}

export default FooterOne;
