import React, { useEffect, useRef, useState } from 'react';
import ScrollAnimation from '../../../../../utils/components/scrollAnimation/ScrollAnimation';
import KAInlineFAQChatBot from '../KABody/KAInlineFAQChatBot';
import { useSelector } from 'react-redux';
import InlineFAQInput from './InlineFAQInput';

function InlineFAQBody({
  responseData,
  handeLinkClick,
  isLoading,
  inputQuery,
  handleGenerateAnswer,
  setInputQuery,
  handRatingTake,
}) {
  const isRagCollapsed = useSelector((state) => state.app.isRagCollapsed);
  const isRagExceedsScroll = useSelector((state) => state.app.isRagExceedsScroll);

  const [isVisible, setIsVisible] = useState(false);
  const scrollableBodyRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      function handleScroll() {
        if (scrollableBodyRef.current) {
          const { scrollHeight, scrollTop, clientHeight } = scrollableBodyRef.current;
          const isScrollable = scrollHeight > clientHeight;
          const isAtBottom = scrollTop + clientHeight >= scrollHeight - 15; // Adjusted for the 20px safe zone

          if (isScrollable) {
            setIsVisible(!isAtBottom);
          } else {
            setIsVisible(false);
          }
        }
      }

      handleScroll();

      if (scrollableBodyRef.current) {
        scrollableBodyRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableBodyRef.current) {
          scrollableBodyRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, 500);
  }, [scrollableBodyRef, responseData]);

  const handleScroll = () => {
    if (scrollableBodyRef.current) {
      scrollableBodyRef.current.scrollTo({
        top: scrollableBodyRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (responseData?.length >= 1) {
      if (isLoading) {
        handleScroll();
      }
    }
  }, [isLoading, responseData]);

  const extendedData = isLoading
    ? [...responseData, { question: inputQuery, isLoading }]
    : responseData;

  if (isRagCollapsed) {
    return <></>;
  }

  return (
    <>
      <div
        style={{ overflow: 'scroll' }}
        className="kaBody customScrollBar"
        ref={scrollableBodyRef}
      >
        {extendedData.map((data, index) => (
          <div key={index}>
            <KAInlineFAQChatBot
              data={data}
              responseAnswer={data?.response}
              handeLinkClick={handeLinkClick}
              index={index}
              handRatingTake={handRatingTake}
            />
          </div>
        ))}
        <ScrollAnimation isVisible={isVisible} style={{ left: '90%', bottom: '150px' }} />
      </div>

      <InlineFAQInput
        handleGenerateAnswer={handleGenerateAnswer}
        inputQuery={isLoading ? '' : inputQuery}
        setInputQuery={setInputQuery}
        isLoading={isLoading}
      />
    </>
  );
}

export default InlineFAQBody;
