import '../styles/xray.css';
import { useSelector } from 'react-redux';
import Two from '../templates/two/Two';
import One from '../templates/one/One';
import Three from './Three';
import Four from './Four';

function XRayWorkflow() {
  const engagementTemplate = useSelector(
    (state) => state.flipcard.data?.flipCard?.engagement?.engagementData?.engagementTemplate
  );

  switch (engagementTemplate) {
    case 'four':
      return <Four />;
    case 'three':
      return <Three />;
    case 'two':
      return <Two />;
    default:
      return <One />;
  }
}

export default XRayWorkflow;
