import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import useNewsletterController from '../../controllers/useNewsletterController';
import Button from '../../../../../utils/components/Button';
import Icon from '../../../../../utils/components/Icon';
import GoogleLogin from '../../../../../utils/presentation/googleLogin/workflow/GoogleLogin';
import CtaFlipIcon from '../../../../../utils/components/CtaFlipIcon';
import TermsAndConditions from '../TermsAndConditions'; // Import Terms and Conditions component

function Centered({ data: userData }) {
  const campaignType = useSelector((state) => state?.flipcard?.data?.config?.campaignType);
  const language = useSelector((state) => state.language.data);
  const { enterEmail_TEXT, emailNotValid_TEXT, required_TEXT, checkbox_TEXT } = language;
  const textColor = useSelector((state) => state.app.ctaTextColor);
  const { handleSubmitNewsletter, isLoading } = useNewsletterController();

  const [data, setData] = useState({ email: '', termsAccepted: false });
  const [errors, setErrors] = useState({});
  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  const termsAndConditions = useSelector(
    (state) => state.flipcard.data.config?.termsAndConditions?.isActive || false
  );

  const { title, description } = userData;

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: undefined,
      }));
    }

    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTermsChange = (isChecked) => {
    setData((prevData) => ({
      ...prevData,
      termsAccepted: isChecked,
    }));

    if (errors.termsAccepted) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        termsAccepted: undefined,
      }));
    }
  };

  const handleSignUp = useCallback(() => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data.email.trim()) {
      newErrors.email = emailNotValid_TEXT;
    } else if (!emailRegex.test(data.email.trim())) {
      newErrors.email = emailNotValid_TEXT;
    }

    // Validate terms only if terms and conditions config is active
    if (termsAndConditions && !data.termsAccepted) {
      newErrors.termsAccepted = checkbox_TEXT;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors)?.length === 0) {
      submitData(data);
    }
  }, [data, emailNotValid_TEXT, required_TEXT, checkbox_TEXT, termsAndConditions]);

  const submitData = (passedData) => {
    handleSubmitNewsletter({
      actionId: userData?._id,
      takeActionData: {
        actionType: userData?.actionData?.actionType,
        ...passedData,
      },
    });
  };

  return (
    <div className="newsletterCenter">
      <CtaFlipIcon color={textColor} />

      <div
        className="newsletterContent ctaCenterContent"
        style={{
          color: textColor,
        }}
      >
        {logo && <img src={logo} alt="Logo" />}

        <h3>{title}</h3>
        <p
          style={{
            backgroundColor: textColor === 'white' ? '#00000080' : '#ffffff80',
          }}
          className="description"
        >
          {description}
        </p>

        <TermsAndConditions
          isRequired={true}
          error={errors.termsAccepted}
          onChange={handleTermsChange}
        />

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 'var(--mpr-3)',
          }}
        >
          <div className="newsletterCenterInputDiv">
            <input
              type="email"
              name="email"
              autoComplete="on"
              onChange={handleInputChange}
              placeholder={enterEmail_TEXT}
            />
            <Button
              style={{
                width: '100%',
              }}
              isLoading={isLoading}
              onClick={handleSignUp}
            >
              <Icon
                name="ri-send-plane-fill"
                style={{
                  fontSize: '15px',
                }}
                color="white"
              />
            </Button>
          </div>
          <GoogleLogin
            onSubmitHandler={submitData}
            isSmall={true}
            style={{
              width: '40px',
            }}
          />
        </div>
        <p
          style={{
            alignSelf: 'flex-start',
            height: '2px',
          }}
        >
          {errors.email && <p className="errorText">{errors.email}</p>}
        </p>
      </div>
    </div>
  );
}

export default Centered;
