import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Front from './Front';
import Back from './Back';
import '../style/flipcard.css';

function FlippableCard({ children }) {
  // Redux state selectors
  const engagementType = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.engagementData?.engagementType
  );
  const [isAlreadyTransformAdded, setIsAlreadyTransformAdded] = useState(false);
  const isFlipped = useSelector((state) => state.app.isFlipped);
  const flipcard = useSelector((state) => state.flipcard);

  const outerBorderRadius = useSelector((state) => state.app.outerBorderRadius);

  // Effect to update isAlreadyTransformAdded state
  useEffect(() => {
    if (isFlipped) {
      setIsAlreadyTransformAdded(isFlipped);
    }
  }, [isFlipped, flipcard]);

  // Function to check if transformation is needed
  const isTransform = () => {
    if (!flipcard?.data?.flipCard?.engagement) {
      return false;
    }

    return isFlipped || isAlreadyTransformAdded;
  };

  // Validate children
  if (React.Children.count(children) !== 2) {
    throw new Error('FlippableCard component expects exactly two children.');
  }

  // If You want to make anything scrollable then add the type here
  const cardStyle = {
    position:
      (engagementType === 'x-ray' ||
        engagementType === 'rag' ||
        engagementType === 'analyticsRag') &&
      'static',
  };

  const isAnimatedBorderVisible = useSelector((state) => state.app.isAnimatedBorderVisible);
  return (
    <div
      className={`mainFlipcard ${isAnimatedBorderVisible ? 'shineBorder' : ''}`}
      style={{ borderRadius: outerBorderRadius }}
    >
      <div
        className="flipCardInner"
        style={{
          transform: isFlipped ? 'rotateY(-180deg)' : 'rotateY(0deg)',
          transition: isTransform() ? 'transform 0.6s' : 'none',
          borderRadius: outerBorderRadius,
        }}
      >
        <div className="flipCardFront" style={cardStyle}>
          {React.cloneElement(children[0])}
        </div>
        <div className="flipCardBack" style={cardStyle}>
          {React.cloneElement(children[1])}
        </div>
      </div>
    </div>
  );
}

export default FlippableCard;
