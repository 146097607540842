import '../styles/link.css';
import Default from '../components/Default';
import useCtaViewHandler from '../../reusable/controllers/useCtaViewHandler';
import { useEffect } from 'react';

function LinkWorkflow({ data }) {
  const { handleCtaView } = useCtaViewHandler();
  useEffect(() => {
    handleCtaView(data?._id);
  }, [data]);

  return data ? <Default data={data} /> : <></>;
}

export default LinkWorkflow;
