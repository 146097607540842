import { useDispatch, useSelector } from 'react-redux';
import { setHideMessage, setShowMessage } from '../../../../redux/slices/appSlice';

const useMessage = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.app.message);
  const { isVisible, messageData } = message;

  const showMessage = (type, message) => {
    dispatch(setShowMessage({ message, type }));

    const timeout = setTimeout(() => {
      dispatch(setHideMessage());
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  };

  return { isVisible, showMessage, messageData };
};

export default useMessage;
