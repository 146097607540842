import { useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import useFeedbackHandler from '../controllers/useFeedbackHandler';
import '../styles/feedback.css';
import { useEffect, useState } from 'react';

function Disclaimer({ text, textAlign }) {
  return (
    <h5 className="Disclaimer" style={{ textAlign }}>
      {text}
    </h5>
  );
}

function RatingSection({ rating, changeRating, feedbackTitle, isRightAligned }) {
  return (
    <div
      className="RatingsDiv"
      style={{
        justifyContent: isRightAligned ? 'flex-end' : 'space-between',
      }}
    >
      {isRightAligned ? (
        <>
          <div style={{ marginRight: 'var(--mpr-3)' }}>
            <StarRatings
              rating={rating}
              starRatedColor="#ffa106"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
              starDimension="15px"
              starSpacing="1px"
              starHoverColor="#ffa106"
              starEmptyColor="white"
            />
          </div>
          <p>{feedbackTitle || 'Rate your experience'}</p>
        </>
      ) : (
        <>
          <p>{feedbackTitle || 'Rate your experience'}</p>
          <div>
            <StarRatings
              rating={rating}
              starRatedColor="#ffa106"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
              starDimension="15px"
              starSpacing="1px"
              starHoverColor="#ffa106"
            />
          </div>
        </>
      )}
    </div>
  );
}

function Feedback({ isShowCta, ragResultId }) {
  const [uiUpdateState, setUiUpdateState] = useState(false);
  const {
    injectionStyle,
    disclaimer,
    feedbackConfig: feedback,
  } = useSelector((state) => state.flipcard.data.config);
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);
  const { rating, changeRating } = useFeedbackHandler(ragResultId);

  const disclaimerTextAlign = language === 'he' ? 'right' : feedback?.isActive ? 'left' : 'center';
  const getParentHeight = () => {
    if (feedback?.isActive && disclaimer) return '90px';
    if (feedback?.isActive || disclaimer) return '65px';
    return undefined;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setUiUpdateState((prevState) => !prevState);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (!disclaimer && !feedback?.isActive) return null;

  return (
    <div
      className="FeedbackContainerParent"
      style={{
        height: injectionStyle === 'inline' ? getParentHeight() : undefined,
      }}
    >
      <div className={`FeedbackContainer ${isShowCta && 'xrayBgBlur'}`}>
        {disclaimer && <Disclaimer text={disclaimer} textAlign={disclaimerTextAlign} />}
        {feedback?.isActive && (
          <RatingSection
            rating={rating}
            changeRating={changeRating}
            feedbackTitle={feedback?.title}
            isRightAligned={language === 'he'}
          />
        )}
      </div>
    </div>
  );
}

export default Feedback;
