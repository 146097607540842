import '../styles/newsletter.css';
import Default from '../components/default/Default';
import Centered from '../components/centered/Centered';
import useCtaViewHandler from '../../reusable/controllers/useCtaViewHandler';
import { useEffect } from 'react';
import LeftAligned from '../components/leftAlign/LeftAligned';

function NewsletterWorkflow({ data }) {
  const { handleCtaView } = useCtaViewHandler();
  useEffect(() => {
    handleCtaView(data?._id);
  }, [data]);

  const section = () => {
    switch (data?.actionData?.actionTemplate) {
      case 'default':
        return <Default data={data} />;
      case 'centered':
        return <Centered data={data} />;
      case 'leftAligned':
        return <LeftAligned data={data} />;
      default:
        return <Default data={data} />;
    }
  };

  return data ? section() : <></>;
}

export default NewsletterWorkflow;
