import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleLeftBorder from '../../../../../utils/components/TitleLeftBorder';
import Divider from '../../../../../utils/components/Divider';
import Icon from '../../../../../utils/components/Icon';
import usePostMessage from '../../../../../utils/controllers/usePostMessage';
import useEngController from '../../../reusable/controllers/useEngController';
import { setIsXrayCollapsed } from '../../../../../redux/slices/appSlice';
import getTextColor from '../../../../../utils/controllers/getTextColor';

function XRayTopBar({ currentEngData, totalEngagements, handlePopEngagementData, isShowCta }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);
  const dispatch = useDispatch();
  const isXrayCollapsed = useSelector((state) => state.app.isXrayCollapsed);
  const headingText = useSelector((state) => state.flipcard.data.flipCard.engagement.title);
  const backgroundColor = useSelector(
    (state) => state.flipcard.data.flipCard.engagement.engagementData.backgroundColor
  );

  const { handleXraytake } = useEngController();
  const poweredBy_TEXT = useSelector((state) => state.language.data.poweredBy_TEXT);
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const bridgedLogo = useSelector((state) => state.app.bridgedLogo);

  const { postMessage } = usePostMessage();

  const logo = useSelector((state) => state.flipcard.data.flipCard.publisher.logo);

  const backIcon = (style) => {
    return (
      <Icon
        name="ri-arrow-left-s-line"
        style={{
          marginRight: 'var(--mpr-3)',
          cursor: 'pointer',
          ...style,
        }}
        onClick={handlePopEngagementData}
      />
    );
  };

  const onCollapseHandler = (data) => {
    dispatch(setIsXrayCollapsed(data));
  };

  const sendCollapseEvent = () => {
    postMessage({
      event: 'toggleCollapsion',
      trackerId: getQueryParam('trackerId'),
    });
  };

  const closeIcon = (style) => {
    if (isXrayCollapsed) {
      return (
        <Icon
          name="ri-arrow-up-s-line"
          onClick={() => {
            onCollapseHandler(false);
          }}
          style={{
            opacity: 0.8,
            cursor: 'pointer',
            ...style,
          }}
        />
      );
    } else {
      return (
        <Icon
          name="ri-close-line"
          onClick={() => {
            onCollapseHandler(true);
            sendCollapseEvent();
          }}
          style={{
            opacity: 0.8,
            cursor: 'pointer',
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (!isXrayCollapsed) {
      handleXraytake(currentEngData?._id);
    }

    // Accessing the bridgedCard element and applying dynamic height
    const bridgedCardElement = document.querySelector('.bridgedCard');
    if (bridgedCardElement) {
      bridgedCardElement.style.height = isXrayCollapsed ? '75px' : '100%';
    }
  }, [isXrayCollapsed]);

  return (
    <div
      className="xrayDefaultTopbar"
      style={{
        cursor: isXrayCollapsed && 'pointer',
      }}
      onClick={() => {
        if (isXrayCollapsed) {
          onCollapseHandler(false);
          sendCollapseEvent();
        }
      }}
    >
      <div
        className="xrayDefaultTopbarTitle"
        style={{
          backgroundColor: backgroundColor,
          color: backgroundColor ? getTextColor(backgroundColor) : 'var(--text-Color)',
        }}
      >
        {language === 'he' ? (
          <>
            {closeIcon({
              marginRight: 'var(--mpr-3)',
            })}
            <h3>
              <span className="firstLine">
                {isXrayCollapsed
                  ? headingText || currentEngData?.questionText
                  : currentEngData?.questionText}
              </span>
              {totalEngagements > 1 &&
                !isXrayCollapsed &&
                backIcon({
                  marginLeft: 'var(--mpr-3)',
                  marginRight: '0px',
                  transform: 'rotate(180deg)',
                })}
            </h3>
          </>
        ) : (
          <>
            <h3>
              {totalEngagements > 1 && !isXrayCollapsed && backIcon()}
              <span className="firstLine">
                {isXrayCollapsed
                  ? headingText || currentEngData?.questionText
                  : currentEngData?.questionText}
              </span>
            </h3>
            {closeIcon()}
          </>
        )}
      </div>

      <Divider />
      <div
        style={{
          padding: 'var(--mpr-1-2)',
          justifyContent: language === 'he' ? 'flex-end' : 'flex-start',
        }}
        className={`engTopbarLogoDiv ${isShowCta && 'xrayBgBlur'}`}
      >
        {!logo && <p>{poweredBy_TEXT}</p>}
        <img
          onClick={() => {
            if (!logo) {
              window.open('https://www.bridged.media/', '_blank');
            }
          }}
          src={logo || bridgedLogo}
        />
      </div>
    </div>
  );
}

export default XRayTopBar;
