import React from 'react';
import { useSelector } from 'react-redux';
import '../../styles/four/fourInlineDesktop.css';
import '../../styles/four/fourInlineMobile.css';

function FourInlineQuestions({ allOptions, selectedOption, onSelectedOptionHandler }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);
  return (
    <div className="xrayInlineDesktopQuestions xrayInlineQuestionsFour">
      <div className="moreQuestions">
        <div className="moreQuestion" style={{ marginTop: '0px' }}>
          {allOptions?.map((data, index) => {
            const isActive = data?.type === selectedOption?.type;
            return (
              <div
                key={index}
                className={`xrayQuestionFourDesktop ${isActive ? 'ActiveMoreQuestionFourDesktop' : ''}`}
                onClick={() => {
                  onSelectedOptionHandler(data);
                }}
              >
                <span
                  className="xrayMoreQuestionTextFourDesktop"
                  style={{
                    textAlign: language === 'he' ? 'right' : 'left',
                    width: '100%',
                  }}
                >
                  {data?.questionText}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FourInlineQuestions;
