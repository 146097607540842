import React from 'react';
import { useSelector } from 'react-redux';

function Quotes({ data }) {
  const language = useSelector((state) => state?.flipcard?.data?.flipCard?.theme?.language);

  const combineQuotes = (quotes) => {
    const combined = quotes.reduce((acc, curr) => {
      const { speaker, quote } = curr;

      if (acc[speaker]) {
        acc[speaker].quotes.push(quote);
      } else {
        acc[speaker] = { speaker, quotes: [quote] };
      }

      return acc;
    }, {});

    return Object.values(combined);
  };

  const combinedQuotes = combineQuotes(data?.quotes || []);

  return (
    <div className="xrayQuotes">
      {combinedQuotes?.map((item, index) => (
        <div
          key={index}
          style={{
            textAlign: language === 'he' ? 'right' : 'left',
            direction: language === 'he' ? 'rtl' : 'ltr',
          }}
          className="xrayQuote"
        >
          <h3>{item?.speaker}</h3>
          {item?.quotes?.map((quote, i) => (
            <p key={i}>"{quote}"</p>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Quotes;
