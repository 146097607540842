import '../styles/dmg.css';
import Footer from '../components/Footer';
import MiddleBar from '../components/MiddleBar';
import useDmgController from '../controller/useDmgController';
import DmgBody from '../components/DmgBody';
import DmgUser from '../components/DmgUser';

function DmgWorkflow() {
  const {
    inputQuery,
    setInputQuery,
    isLoading,
    onHandleDmgClick,
    responseData,
    handleQueryDownload,
    handleAnswerPdfDownload,
  } = useDmgController();

  return (
    <div className="dmgFullPage">
      <DmgUser />
      {responseData?.length ? (
        <DmgBody
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          isLoading={isLoading}
          onHandleDmgClick={onHandleDmgClick}
          responseData={responseData}
          handleQueryDownload={handleQueryDownload}
          handleAnswerPdfDownload={handleAnswerPdfDownload}
        />
      ) : (
        <MiddleBar
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          isLoading={isLoading}
          onHandleDmgClick={onHandleDmgClick}
        />
      )}
      <Footer />
    </div>
  );
}

export default DmgWorkflow;
