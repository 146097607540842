import React, { useEffect, useState } from 'react';
import Input from '../../../../../utils/components/Input';
import Button from '../../../../../utils/components/Button';
import useCtaViewHandler from '../../../../ctas/reusable/controllers/useCtaViewHandler';
import { useSelector } from 'react-redux';
import useNewsletterController from '../../../../ctas/newsletter/controllers/useNewsletterController';

function GatedEmailCollection({ currentAction }) {
  const { handleCtaView } = useCtaViewHandler();
  const { handleSubmitNewsletter, isLoading } = useNewsletterController();
  const language = useSelector((state) => state.language.data);
  const { submit_TEXT } = language;

  const [data, setData] = useState({
    fullname: '',
    email: '',
    termsAccepted: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitData = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!data.email) {
      return;
    }
    handleSubmitNewsletter({
      actionId: currentAction?._id,
      takeActionData: {
        actionType: currentAction?.actionData?.actionType,
        ...data,
      },
    });
  };

  useEffect(() => {
    handleCtaView(currentAction?._id);
  }, [currentAction?._id]);

  return (
    <form onSubmit={submitData} className="kaInputDivOne">
      <Input
        type="email"
        name="email"
        onChange={handleInputChange}
        placeholder="Enter your email to continue using the tool"
        isRequired={true}
      />

      <Button isLoading={isLoading}>
        {currentAction?.actionData?.signUpButtonText || submit_TEXT}
      </Button>
    </form>
  );
}

export default GatedEmailCollection;
