import React from 'react';

import FourInlineDesktop from '../templates/four/desktop/FourInlineDesktop';
import FourInlineMobile from '../templates/four/mobile/FourInlineMobile';
import useGetWindowWidth from '../../../../utils/controllers/useGetWindowWidth';

function Inline() {
  const width = useGetWindowWidth();

  if (width >= 500) {
    return <FourInlineDesktop />;
  }

  return <FourInlineMobile />;
}

export default Inline;
