import React, { useEffect } from 'react';
import EndScreenLayout from '../../../layout/EndScreenLayout';
import '../styles/endScreen.css';
import { useSelector } from 'react-redux';
import SocialIcons from '../components/SocialIcons';
import { useEndScreenViewMutation } from '../../../services/api';
import FlipIcon from '../../../utils/components/FlipIcon';

function EndScreenWorkflow() {
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);
  // Select necessary data from Redux store
  const isEndScreen = useSelector((state) => state.app.isShowEndScreen);
  const isEngagement = useSelector((state) => state?.flipcard?.data?.flipCard?.engagement?._id);
  const flipCard = useSelector((state) => state?.flipcard?.data?.flipCard);
  const { title, description, shareEnabled, _id: endScreenId } = flipCard?.endScreen;

  // Perform end screen view mutation
  const [_VIEW_ENDSCREEN] = useEndScreenViewMutation();

  useEffect(() => {
    if (endScreenId && isEndScreen) {
      const handleEndScreenView = async () => {
        if (mode === 'create' || mode === 'preview') {
          return;
        }
        // Call the end screen view mutation
        await _VIEW_ENDSCREEN({
          endScreenId: endScreenId,
        });
      };
      handleEndScreenView();
    }
  }, [endScreenId, isEndScreen, _VIEW_ENDSCREEN]);

  return (
    <EndScreenLayout>
      <div className="defaultEndScreen">
        {isEngagement && <FlipIcon />}
        <img src="/icons/verify.png" className="endscreenVerifyIcon" alt="Success" />
        <h3 style={{ textAlign: 'center' }}>{title}</h3>
        <p style={{ textAlign: 'center' }}>{description}</p>

        {/* Render social icons if share is enabled */}
        {shareEnabled && <SocialIcons endScreenId={endScreenId} />}
      </div>
    </EndScreenLayout>
  );
}

export default EndScreenWorkflow;
