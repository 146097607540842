import { useEffect } from 'react';

const useLoadAdSenseScript = (dataAdClient) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.setAttribute('data-ad-client', dataAdClient);
      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }
  }, [dataAdClient]);
};

export default useLoadAdSenseScript;
