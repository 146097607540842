import { useDispatch, useSelector } from 'react-redux';
import { useCtaTakeMutation } from '../../../../services/api';
import { setIsCtaTaken, setIsShowEndScreen } from '../../../../redux/slices/appSlice';
import useMessage from '../../../../utils/presentation/message/controllers/useMessage';
import useTriggerEngagementCtaTake from '../../../../utils/controllers/useTriggerEngagementCtaTake';

function useNewsletterController() {
  const thankYouForRegistration_TEXT = useSelector(
    (state) => state.language.data.thankYouForRegistration_TEXT
  );
  const isEndScreen = useSelector((state) => state.flipcard?.data?.flipCard?.endScreen?._id);

  const isGatedEngagement = useSelector((state) => state?.flipcard?.data?.config?.gatedEngagement);

  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);

  const { showMessage } = useMessage();
  const dispatch = useDispatch();
  const [_CTA_TAKE, { isLoading }] = useCtaTakeMutation();
  const { triggerActionTake } = useTriggerEngagementCtaTake();

  const handleEndScreen = () => {
    if (isEndScreen) {
      dispatch(setIsShowEndScreen(true));
    }
  };

  const handleSubmitNewsletter = async (sentData) => {
    if (mode === 'create') {
      return;
    }
    const response = await _CTA_TAKE(sentData);

    if (response?.data?.success) {
      triggerActionTake();
      showMessage('success', thankYouForRegistration_TEXT);
      dispatch(setIsCtaTaken(true));

      if (!isGatedEngagement) {
        handleEndScreen();
      }
    }

    if (response?.error) {
      showMessage('error', response?.error?.data?.errorObject?.userErrorText);
    }
  };

  return { handleSubmitNewsletter, isLoading };
}

export default useNewsletterController;
