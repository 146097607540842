import { createSlice } from '@reduxjs/toolkit';
import flipcardState from '../../constants/flipcard/flipcardState';

export const flipcardSlice = createSlice({
  name: 'flipcard',
  initialState: {
    data: flipcardState,
  },
  reducers: {
    setFlipcard: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setFlipcard } = flipcardSlice.actions;

export default flipcardSlice.reducer;
