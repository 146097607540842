import '../styles/advertisement.css';
import AdvertisementAsset from '../components/AdvertisementAsset';
import Body from '../components/Body';
import useAdvertisementHandler from '../controllers/useAdvertisementHandler';

function AdvertisementWorkflow() {
  const { cta, handleClick } = useAdvertisementHandler();

  return (
    <>
      {cta?._id && (
        <div onClick={handleClick} className="advertisement">
          <Body cta={cta} />
          <AdvertisementAsset {...cta?.background} />
        </div>
      )}
    </>
  );
}

export default AdvertisementWorkflow;
