import React from 'react';
import Marquee from 'react-fast-marquee';
import { useSelector } from 'react-redux';
import { getColorOpacityPercentage } from '../../../../../utils/controllers/colors';
import useDeaQuerys from '../../controllers/useDeaQuerys';
import Icon from '../../../../../utils/components/Icon';

function SidebarOneFAQ({ handleGenerateAnswer, responseDataLength }) {
  const campaignType = useSelector((state) => state?.flipcard?.data?.config?.campaignType);
  const { quieres, deleteQuery } = useDeaQuerys();
  const kaFAQQuestions = useSelector((state) => state.app.kaFAQQuestions);

  const primaryColor = useSelector((state) => state.flipcard.data.flipCard.theme.primaryColor);

  const bgColorOpacity = getColorOpacityPercentage(primaryColor, 0.05);

  const shouldShowFAQ = () => {
    if (responseDataLength > 0) {
      return false;
    }

    if (campaignType === 'dea') {
      if (quieres?.length > 0) {
        return true;
      }
    }

    if (kaFAQQuestions?.length && responseDataLength === 0) {
      return true;
    }

    return false;
  };

  const faqList = () => {
    if (campaignType === 'dea') {
      return [...(kaFAQQuestions || []), ...(quieres || [])];
    }

    return kaFAQQuestions;
  };

  if (shouldShowFAQ()) {
    return (
      <div className="kaFaqSidebarOneFAQs">
        <div className="kaFaqSidebarOneFAQsGradient gradient-left"></div>
        <div className="kaFaqSidebarOneFAQsGradient gradient-right"></div>

        <Marquee speed={50} pauseOnHover={campaignType === 'dea' ? true : false}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {faqList()?.map((data, index) => {
              return (
                <div
                  className="kaFaqSidebarOneCard"
                  style={{ backgroundColor: bgColorOpacity }}
                  key={index}
                  onClick={() => {
                    setTimeout(() => {
                      handleGenerateAnswer(data);
                    }, 200);
                  }}
                >
                  {data?.title}

                  {campaignType === 'dea' && (
                    <div
                      className="kaFaqSidebarOneCardDelete"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteQuery(data?.title);
                      }}
                    >
                      <Icon name="ri-close-line" style={{ fontSize: '1.5rem' }} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Marquee>
      </div>
    );
  }

  return <></>;
}

export default SidebarOneFAQ;
