import React from 'react';
import '../style/loader.css';

function Loader({ style }) {
  return (
    <div className="spinner_container" aria-label="Loading...">
      <i
        className="spinner_item"
        style={{
          ...style,
        }}
      ></i>
    </div>
  );
}

export default Loader;
