import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from './Icon';
import { setCrouselCurrentCta, setIsCtaTaken } from '../../redux/slices/appSlice';

function CtaBackButton({ isCloseBtn }) {
  const dispatch = useDispatch();
  const { back_TEXT, close_TEXT } = useSelector((state) => state.language.data);
  return (
    <div className="flipIcon">
      <span
        className="ctaBackButton"
        onClick={() => {
          if (isCloseBtn) {
            dispatch(setIsCtaTaken(true));
          } else {
            dispatch(setCrouselCurrentCta(null));
          }
        }}
      >
        <Icon
          name={isCloseBtn ? 'ri-close-line' : 'ri-arrow-left-s-line'}
          style={{
            fontSize: '20px',
          }}
        />
        <p>{isCloseBtn ? close_TEXT : back_TEXT}</p>
      </span>
    </div>
  );
}

export default CtaBackButton;
