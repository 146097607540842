import React from 'react';
import { useSelector } from 'react-redux';
import useEngViewHandler from '../../engagements/reusable/controllers/useEngViewHandler';
import engagementFinder from '../../../constants/engagements/engagementFinder';
import AdvertisementWorkflow from '../../common/advertisement/workflow/AdvertisementWorkflow';
import EngLayout from '../../../layout/EngLayout';
import GatedEngagementWorkflow from '../../engagements/workflow/GatedEngagementWorkflow';

function Front() {
  const isCtaPreview = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.ctaPreviewConfig?.isActive
  );

  useEngViewHandler();

  // Retrieve engagement type from Redux store
  const engagementType = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.engagementData?.engagementType
  );

  // Find engagement component based on engagement type
  const renderEngagementComponent = () => {
    const engagementTypeObject = engagementFinder(engagementType);
    if (engagementTypeObject) {
      const Component = engagementTypeObject.component;
      return <Component />;
    }

    // Return a default component or handle the error scenario if needed
    return <div />;
  };

  return (
    <EngLayout>
      <div style={{ flex: isCtaPreview && 2, height: !isCtaPreview && '100%' }}>
        {renderEngagementComponent()}
      </div>
      <GatedEngagementWorkflow />
      {isCtaPreview && <AdvertisementWorkflow />}
    </EngLayout>
  );
}

export default Front;
