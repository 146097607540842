import { useEffect } from 'react';
import { useEngagementViewMutation } from '../../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEngagementView } from '../../../../redux/slices/appSlice';

const useEngViewHandler = () => {
  const mode = useSelector((state) => state?.flipcard?.data?.config?.mode);
  const campaignType = useSelector((state) => state?.flipcard?.data?.config?.campaignType);
  const dispatch = useDispatch();
  // Select necessary data from Redux store
  const engagementId = useSelector((state) => state?.flipcard?.data?.flipCard?.engagement?._id);
  const isEngagementView = useSelector((state) => state.app.isEngagementView);
  const [_VIEW_ENGAGEMENT, { error }] = useEngagementViewMutation();

  useEffect(() => {
    const handleEngagementView = async () => {
      if (mode === 'create' || mode === 'preview') {
        return;
      }
      if (engagementId && !isEngagementView) {
        // Call the engagement view mutation
        const { data } = await _VIEW_ENGAGEMENT({
          engagementId: engagementId,
        });

        // Dispatch action to update isEngagementView state
        if (data) {
          dispatch(setIsEngagementView(true));
        }
      }
    };

    if (campaignType === 'dea') {
      return;
    }

    handleEngagementView();
  }, [dispatch, engagementId, isEngagementView, _VIEW_ENGAGEMENT, campaignType]);
};

export default useEngViewHandler;
