import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import SinglePageInput from './SinglePageInput';

function TopBar({
  bgColorOpacity,
  handleGenerateAnswer,
  isLoading,
  inputQuery,
  setInputQuery,
  responseData,
  isShowEmailForm,
  isShowBanner,
}) {
  const title = useSelector((state) => state?.flipcard?.data?.flipCard?.engagement?.title);
  const description = useSelector(
    (state) => state?.flipcard?.data?.flipCard?.engagement?.description
  );

  if (responseData?.length > 0) {
    return null;
  }

  return (
    <div className="singlePageOneTopBar">
      <div className="singlePageOneTopBarHeading">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>

      {responseData?.length === 0 ? (
        <SinglePageInput
          isShowEmailForm={isShowEmailForm}
          bgColorOpacity={bgColorOpacity}
          isShowBanner={isShowBanner}
          isLoading={isLoading}
          inputQuery={inputQuery}
          setInputQuery={setInputQuery}
          responseData={responseData}
          handleGenerateAnswer={handleGenerateAnswer}
        />
      ) : null}
    </div>
  );
}

export default TopBar;
