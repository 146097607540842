import React from 'react';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';

function KAAnswer({ data, responseAnswer }) {
  const { answerText, questionText } = useSelector((state) => state.language.data);

  if (responseAnswer?.length) {
    return (
      <div className="kaAnswers">
        <div>
          <h4 className="kaAnswerHeading">{questionText}:</h4>
          <h3>{data?.question}</h3>
        </div>
        <div>
          <h4 className="kaAnswerHeading">{answerText}:</h4>
          <Markdown
            className="kaAnswerMainDiv"
            components={{
              a: ({ node, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props} />,
            }}
          >
            {responseAnswer}
          </Markdown>
        </div>
      </div>
    );
  }

  return <></>;
}

export default KAAnswer;
