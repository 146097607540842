import React from 'react';
import Summary from '../components/Summary';
import KeyFacts from '../components/KeyFacts';
import KeyPeople from '../components/KeyPeople';
import Quotes from '../components/Quotes';
import Links from '../components/Links';
import FourInlineTerminologies from '../components/four/FourInlineTerminology';

function CurrentXrayWorkflow({ currentEngData, isPadding }) {
  const type = currentEngData?.type;

  const section = () => {
    switch (type) {
      case 'summary':
        return <Summary data={currentEngData} />;
      case 'keyFact':
        return <KeyFacts data={currentEngData} />;
      case 'keyPeople':
        return <KeyPeople data={currentEngData} />;
      case 'quotes':
        return <Quotes data={currentEngData} />;
      case 'relatedTopics':
        return <Links data={currentEngData} keyName="relatedActions" />;
      case 'relatedAuthors':
        return <Links data={currentEngData} keyName="relatedActions" />;
      case 'linkActions':
        return <Links data={currentEngData} keyName="linkActions" />;
      case 'terminology':
        return <FourInlineTerminologies data={currentEngData} />;
      default:
        return <div />;
    }
  };

  return (
    <div
      className="xrayBody"
      style={{
        padding: isPadding && 'var(--mpr-1-2)',
      }}
    >
      {section()}
    </div>
  );
}

export default CurrentXrayWorkflow;
